import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'landing-page.component',
  templateUrl: './terminate-dashboard-withdraw.component.html',
  styleUrls: ['./terminate-dashboard-withdraw.component.css']
})
export class TerminateDashboardWithdrawComponent implements OnInit {

  withdrawForm: FormGroup;
  ticket: any;
  employeeName: any;
  employeeId: any;
  data: any[] = [];
  errorMessage: any;
  user: any;
  password: any;
  auth: any;
  submitted : boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.withdrawForm = this.formBuilder.group({
      incorrectinformation: [null]
    });
  }

  ngOnInit() {
    this.submitted = false;
    localStorage.setItem("audit", '');
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("withdraw/");
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          this.employeeName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.employeeId = a.fields.customfield_31900;
        }
      });
    }
  }


  onSubmit(): void {
    console.warn('Form Values', this.withdrawForm.value);
    console.log(this.withdrawForm.valid);
    if (this.withdrawForm.valid) {
      this.submitted = true;    
      this.pushDetailsToJIRA(this.withdrawForm.value);
    }
  }


  pushDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket + '/comment';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    this.http.post<any>(environment.jiraAPI + params, { body: formValues.incorrectinformation }, { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {           
            this.updateStatus();
          } else {
            alert("An Error occured while adding comments to the ticket, please find the error " + JSON.stringify(data?.errors));
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }


  updateStatus() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket + '/transitions?expand=transitions.fields';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let transition = {
      "id": "31"
    };
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ transition }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {         
            this.pushAuditDetailsToJIRA(this.withdrawForm.value);    
            alert("withdraw Details Submited Successfully");    
            this.router.navigate(['../../terminate'], { relativeTo: this.route });      
          } else {
            alert("An Error occured while Transitioning the ticket status to Withdraw, please find the error " + JSON.stringify(data?.errorMessages));
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }

  pushAuditDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formAuditPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  } 

  formAuditPayload(formValues: any) {
    let fields: any = {};
    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let previousAudit: String = "On " + systemDate + " , " + loginUser + " has added following comments on the ticket : " + JSON.stringify(formValues.incorrectinformation);
    let customfield_66000: String = previousAudit + ' ' + "On " + systemDate + " , " + loginUser + " has transitioned the status of the ticket from Open to Withdrawn.";
    fields.customfield_66000 = customfield_66000;
    console.log('Fields ', fields);
    return fields;
  }
  
/*
    pushDetailsToJIRA(formValues: any) {
      const params = new HttpParams()
       .set('username', this.user)
       .set('password', this.password)
       .set('ticket', this.ticket)
     const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
     this.http.post<any>(environment.api + "offboarding/withdraw", {body: formValues.incorrectinformation}, { params, headers })
       .subscribe({
         next: data => {
           console.log('Data is ', data);
           if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {
                 this.updateStatus();           
           } else {
                 alert("An Error occured while withdrawing ticket, please find the error " +  JSON.stringify(data?.errors));             
           }  
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })
   } 
   
 
     pushAuditDetailsToJIRA(formValues: any) {
       const params = new HttpParams()
       .set('username', this.user)
       .set('password', this.password)
       .set('ticket', this.ticket)
     const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
     let fields = this.formAuditPayload(formValues);
     this.http.put<any>(environment.api + "offboarding/edit", JSON.stringify({ fields }), { params, headers })
       .subscribe({
         next: data => {
           console.log('Data is ', data);
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })  
     } 
 
   updateStatus() {
     const params = new HttpParams()
       .set('username', this.user)
       .set('password', this.password)
       .set('ticket', this.ticket)
     const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
     let transition = {
       "id": "31"
     };
     this.http.post<any>(environment.api + "offboarding/transition", JSON.stringify({ transition }), { params, headers })
       .subscribe({
         next: data => {
           console.log('Data is ', data);
           if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {
             this.pushAuditDetailsToJIRA(this.withdrawForm.value);
             alert("withdraw Details Submited Successfully"); 
             this.router.navigate(['../../terminate'], { relativeTo: this.route }); 
           } else {
             alert("An Error occured while Transitioning the ticket status to Withdraw, please find the error " +  JSON.stringify(data?.errorMessages)); 
           }   
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })
   }
*/

}