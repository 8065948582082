import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { IDropdownSettings, } from 'ng-multiselect-dropdown';
import { exit } from 'process';

@Component({
  selector: 'landing-page.component',
  templateUrl: './hire-dashboard-eng-edit.component.html',
  styleUrls: ['./hire-dashboard-eng-edit.component.css']
})
export class HireDashboardEngEditComponent implements OnInit {

  onboardingForm: FormGroup;
  salesflag: boolean = false;
  editorialflag: boolean = false;
  additionalflag: boolean = false;
  ticket: any;
  data: any[] = [];
  users: any[] = [];
  errorMessage: any;
  hrEmployeeType: any[] = [];
  hireType: any[] = [];
  phoneDetails: any[] = [];
  workSpaceLocation: any[] = [];
  workSpaceImprovements: any[] = [];
  computingEquipments: any[] = [];
  applicationGroup: any[] = [];
  amexCardRequired: any[] = [];
  newSalesForceAccount: any[] = [];
  salesForceUserType: any[] = [];
  editorialPersona: any[] = [];
  bwriteRole: any[] = [];
  deliverEquipment: any[] = [];
  user: any;
  password: any;
  showBWrite: boolean = false;
  auth: any;
  submitted: boolean = false;
  isVendorOrContractor: boolean = false;
  isVendor: boolean = false;
  engteams: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  showName: boolean = false;
  showwno: boolean = false;
  showEquipment: boolean = false;
  ejustification: boolean = false;
  devicetype_dropdownList: any[] = [];
  showEqipJustification: boolean = false;
  // showVDI: boolean = false;
  showgitlab: boolean = false;
  showaws: boolean = false;
  engComputingDropdown: any[] = [];
  gitLabGroupDropdown: any[] = [];
  gitLabRoleDropdown: any[] = [];
  workerClass: string = '';
  fullName: any;
  awsowners: any[] = [];
  ownersList: any[] = [];
  userId: any;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.onboardingForm = this.formBuilder.group({
      firstname: [""],
      lastname: [""],
      manager: [""],
      wclass: [""],
      //  eteam:[null, Validators.required],
      buddy: [null],
      modelaccess: [null, Validators.required],
      htype: ["", Validators.required],
      location: ["", Validators.required],
      workspaceno: [null, Validators.required],
      dequipment: ["", Validators.required],
      newhirereferral: [null, Validators.required],
      newhireequipment: [null, Validators.required],
      pname: [""],
      engEquipment: [""],
      ejustification: [""],
      newhireVDI: [null, Validators.required],
      //  vdi: [""],
      newhireaws: [null, Validators.required],
      awsaccountname: [null],
      awsaccountno: [null],
      awsrole: [null],
      awsowner: [""],
      newhiregitlab: [null, Validators.required],
      gitlabgroup: [""],
      gitlabrole: [""],
      additionalapps: [null],
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.showBWrite = false;
    this.isVendorOrContractor = false;
    this.isVendor = false;
    this.showName = false;
    this.showwno = false;
    this.showEquipment = false;
    this.ejustification = false;
    this.showEqipJustification = false;
    // this.showVDI = false;
    this.showgitlab = false;
    this.showaws = false;
    localStorage.setItem("audit", '');
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("edit/");
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          console.log('ticket ', a);
          this.fullName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.onboardingForm = this.formBuilder.group({
            firstname: [a.fields.customfield_10659],
            lastname: [a.fields.customfield_10660],
            manager: [a.fields.customfield_15400.displayName],
            wclass: [a.fields.customfield_26136.value],
            //  eteam:[null, Validators.required],
            buddy: [null],
            modelaccess: [null, Validators.required],
            htype: ["", Validators.required],
            difffirstname: [null],
            location: ["", Validators.required],
            workspaceno: [null, Validators.required],
            dequipment: ["", Validators.required],
            newhirereferral: [null, Validators.required],
            newhireequipment: [null, Validators.required],
            pname: [""],
            engEquipment: [""],
            ejustification: [""],
            newhireVDI: [null, Validators.required],
            //   vdi: [""],
            newhireaws: [null, Validators.required],
            awsaccountname: [null],
            awsaccountno: [null],
            awsrole: [null],
            awsowner: [""],
            newhiregitlab: [null, Validators.required],
            gitlabgroup: [""],
            gitlabrole: [""],
            additionalapps: [null],
          });
        }
        this.onboardingForm.controls['modelaccess'].setValidators([Validators.required, Validators.pattern('.*[0-9].*')]);
        //   this.workerClass = a.fields.customfield_26136.value;
        if (this.onboardingForm.controls['wclass'].value === 'Vendor' || this.onboardingForm.controls['wclass'].value === 'Contractor') {
          this.isVendorOrContractor = true;
        } else {
          this.isVendorOrContractor = false;
        }
        if (this.onboardingForm.controls['wclass'].value === 'Vendor') {
          this.isVendor = true;
          this.onboardingForm.controls['buddy'].clearValidators();
          this.onboardingForm.controls['buddy'].updateValueAndValidity();
        } else {
          this.isVendor = false;
          this.onboardingForm.controls['buddy'].setValidators([Validators.required, Validators.pattern('.*[0-9].*')]);
        }
      });
    }

    /*  this.engteams = [
        'Pegasus',
        'Wolverines',
        'Titanium',
        'Vibranium'
      ]; */
    this.getAWSOwners();
    this.getDropdownValues();
    this.dropdownSettings = {
      allowSearchFilter: true,
    };
  }

  // System Acccess
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  onSubmit(): void {
    console.warn('Form Values', this.onboardingForm.value);
    console.log(this.onboardingForm.valid);
    if (this.onboardingForm.valid) {
      this.submitted = true;
      this.pushDetailsToJIRA(this.onboardingForm.value);
    } else {
      alert("Please review the form for incomplete or incorrect entries");
    }
  }

  getnewhirereferral(event: any) {
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes')) {
      this.showName = true;
      this.onboardingForm.controls['pname'].setValidators([Validators.required, Validators.maxLength(255)]);
    }
    else {
      this.showName = false;
      this.onboardingForm.controls['pname'].clearValidators();
      this.onboardingForm.controls['pname'].updateValueAndValidity();
    }
  }

  getnewhireequipment(event: any) {
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes')) {
      this.showEquipment = true;
      this.onboardingForm.controls['engEquipment'].setValidators(Validators.required);
      this.onboardingForm.controls['dequipment'].setValidators(Validators.required);
    }
    else {
      this.showEquipment = false;
      this.onboardingForm.controls['engEquipment'].clearValidators();
      this.onboardingForm.controls['engEquipment'].updateValueAndValidity();
      this.onboardingForm.controls['dequipment'].clearValidators();
      this.onboardingForm.controls['dequipment'].updateValueAndValidity();
    }
  }

  getEquipment(event: any) {
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined &&
      (event.target?.value.toString().toLowerCase().includes('bbvpn') || event.target?.value.toString().toLowerCase().includes('macbook')))) {
      this.showEqipJustification = true;
      this.onboardingForm.controls['ejustification'].setValidators([Validators.required, Validators.maxLength(255)]);
    }
    else {
      this.showEqipJustification = false;
      this.onboardingForm.controls['ejustification'].clearValidators();
      this.onboardingForm.controls['ejustification'].updateValueAndValidity();
    }
  }

  /*  getnewhireVDI(event: any) {
      console.log('Event ', event);
      if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes')) {
        this.showVDI = true;
        this.onboardingForm.controls['vdi'].setValidators([Validators.required,Validators.maxLength(255)]);   
      }
      else {
        this.showVDI = false;
        this.onboardingForm.controls['vdi'].clearValidators();
        this.onboardingForm.controls['vdi'].updateValueAndValidity();
      }
    } */

  getnewhireaws(event: any) {
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes')) {
      this.showaws = true;
      this.onboardingForm.controls['awsaccountname'].setValidators([Validators.required, Validators.maxLength(255)]);
      this.onboardingForm.controls['awsaccountno'].setValidators([Validators.required, Validators.maxLength(255)]);
      this.onboardingForm.controls['awsrole'].setValidators([Validators.required, Validators.maxLength(255)]);
      //  this.onboardingForm.controls['awsowner'].setValidators([Validators.required, Validators.pattern('.*[0-9].*')]);
    }
    else {
      this.showaws = false;
      this.onboardingForm.controls['awsaccountname'].clearValidators();
      this.onboardingForm.controls['awsaccountname'].updateValueAndValidity();
      this.onboardingForm.controls['awsaccountno'].clearValidators();
      this.onboardingForm.controls['awsaccountno'].updateValueAndValidity();
      this.onboardingForm.controls['awsrole'].clearValidators();
      this.onboardingForm.controls['awsrole'].updateValueAndValidity();
      this.onboardingForm.controls['awsowner'].clearValidators();
      this.onboardingForm.controls['awsowner'].updateValueAndValidity();
    }
  }

  getnewhiregitlab(event: any) {
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes')) {
      this.showgitlab = true;
      this.onboardingForm.controls['gitlabgroup'].setValidators(Validators.required);
      this.onboardingForm.controls['gitlabrole'].setValidators(Validators.required);
    }
    else {
      this.showgitlab = false;
      this.onboardingForm.controls['gitlabgroup'].clearValidators();
      this.onboardingForm.controls['gitlabgroup'].updateValueAndValidity();
      this.onboardingForm.controls['gitlabrole'].clearValidators();
      this.onboardingForm.controls['gitlabrole'].updateValueAndValidity();
    }
  }

  getWorkSpaceLocation(event: any) {
    this.showwno = false;
    let workspaceLocation = event.target.value;
    if (workspaceLocation !== null && workspaceLocation !== undefined && workspaceLocation !== "") {
      if (workspaceLocation.toLowerCase().includes('arlington') || workspaceLocation.toLowerCase().includes('k street')) {
        this.showwno = true;
        this.onboardingForm.controls['workspaceno'].setValidators(Validators.required);
      } else {
        this.showwno = false;
        this.onboardingForm.controls['workspaceno'].clearValidators();
        this.onboardingForm.controls['workspaceno'].updateValueAndValidity();
      }
    }
  }


  getUsers(input: string) {
    this.auth = localStorage.getItem("Auth");
    this.users = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'groupuserpicker?query=' + input;
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.users = data?.users?.users;
          console.log('User is ', this.users);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }


  getAWSOwners() {
    this.auth = localStorage.getItem("Auth");
    this.users = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'group/member?groupname=aws%23account-owners';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.ownersList = data?.values;
          if (this.ownersList !== null && this.ownersList !== undefined && this.ownersList?.length > 0) {
            this.ownersList.forEach(element => {
              if (element.displayName !== null && element.displayName !== undefined && element.active) {
                this.awsowners.push(element.displayName);
              }
            });
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }

  getDropdownValues() {
    this.auth = localStorage.getItem("Auth");
    this.workSpaceLocation = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'issue/createmeta/HRSD/issuetypes/15500?maxResults=100';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {

          const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {


            // Hire Type         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_35300" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const hireTypeList: any[] = element.allowedValues;
                  hireTypeList.forEach(item => {
                    this.hireType.push(item.value);
                  })
                }
              });
            }


            // Workspace Location      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceLocationList: any[] = element.allowedValues;
                  workSpaceLocationList.forEach(item => {
                    if (item.disabled === false) {
                      this.workSpaceLocation.push(item.value);
                    }  
                  })
                }
              });
            }


            // Deliver Equipment To
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26119" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const deliverEquipmentList: any[] = element.allowedValues;
                  deliverEquipmentList.forEach(item => {
                    this.deliverEquipment.push(item.value);
                  })
                }
              });
            }

            // Engineering Computing Equipment
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72012" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const engComputingDropdownList: any[] = element.allowedValues;
                  engComputingDropdownList.forEach(item => {
                    this.engComputingDropdown.push(item.value);
                  })
                }
              });
            }

            // GitLab Group
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72007" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const gitLabGroupDropdownist: any[] = element.allowedValues;
                  gitLabGroupDropdownist.forEach(item => {
                    this.gitLabGroupDropdown.push(item.value);
                  })
                }
              });
            }


            // GitLab Role
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72008" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const gitLabRoleDropdownList: any[] = element.allowedValues;
                  gitLabRoleDropdownList.forEach(item => {
                    this.gitLabRoleDropdown.push(item.value);
                  })
                }
              });
            }

          }

          console.log('Hire Type ', this.hireType);
          console.log('WorkSpace Location ', this.workSpaceLocation);
          console.log('Deliver Equipment To ', this.deliverEquipment);
          console.log('Engineering Computing Equipment ', this.engComputingDropdown);
          console.log('Gitlab Group ', this.gitLabGroupDropdown);
          console.log('Gitlab Role ', this.gitLabRoleDropdown);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }



  pushDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            this.updateStatus();
          } else {
            alert("An Error occured while submitting new hire details to Jira.");
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }



  updateStatus() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket + '/transitions?expand=transitions.fields';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let transition = {
      "id": "41"
    };
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ transition }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {
            this.pushAuditDetailsToJIRA();
            alert("Onboarding Details Submited Successfully");
            this.router.navigate(['../../hire'], { relativeTo: this.route });
          } else {
            alert("An Error occured while Transitioning the ticket status to Pending Account Creation.");
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }


  pushAuditDetailsToJIRA() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formAuditPayload();
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }


  formPayload(formValue: any) {
    console.log('Payload ', formValue);
    let fields: any = {};

    if (formValue.buddy !== null && formValue.buddy !== undefined && formValue.buddy != "") {
      let customfield_61100 = { name: formValue.buddy };
      fields.customfield_61100 = customfield_61100;
    }
    if (formValue.modelaccess !== null && formValue.modelaccess !== undefined && formValue.modelaccess != "") {
      let customfield_26322 = { name: formValue.modelaccess };
      fields.customfield_26322 = customfield_26322;
    }
    if (formValue.location !== null && formValue.location !== undefined && formValue.location != "") {
      let customfield_26137 = { value: formValue.location };
      fields.customfield_26137 = customfield_26137;
      /*  if (formValue.location === 'Arlington') {
            let customfield_26330 = "Clean workspace: Remove old materials from the surface of the desk and inside the cabinets. Wipe the desk clean and make sure there is a monitor, keyboard, and mouse. \n Name Plate: Create and place a Name Plate with the employee's name - " + this.fullName + ". \n Chair: Ensure there is a chair and that it is functional";
            fields.customfield_26330 = customfield_26330;
        } */
    }
    if (formValue.workspaceno !== null && formValue.workspaceno !== undefined && formValue.workspaceno != "") {
      let customfield_26329 = formValue.workspaceno;
      fields.customfield_26329 = customfield_26329;
    }


    if (formValue.dequipment !== null && formValue.dequipment !== undefined && formValue.dequipment != "") {
      let customfield_26119 = { value: formValue.dequipment };
      fields.customfield_26119 = customfield_26119;
    }
    /* console.log('Worker Class ', this.workerClass);
     if (this.workerClass !== null && this.workerClass !== undefined && this.workerClass != "" && this.workerClass.toString().toLowerCase() === 'manager') {
       let customfield_59600 = { value: 'Yes' };
       fields.customfield_59600 = customfield_59600;
     } else {
       let customfield_59600 = { value: 'No' };
       fields.customfield_59600 = customfield_59600;
     } */

    if (formValue.wclass !== null && formValue.wclass !== undefined && formValue.wclass != "") {
      if (formValue.wclass === 'Manager') {
        let customfield_59600 = { value: 'Yes' };
        fields.customfield_59600 = customfield_59600;
      } else {
        let customfield_59600 = { value: 'No' };
        fields.customfield_59600 = customfield_59600;
      }
    }


    //  let customfield_31903 = { value: this.workerClass };
    //  fields.customfield_31903 = customfield_31903;


    let customfield_26304 = { value: 'Standard' };
    fields.customfield_26304 = customfield_26304;



    if (formValue.htype !== null && formValue.htype !== undefined && formValue.htype != "") {
      let customfield_35300 = { value: formValue.htype };
      fields.customfield_35300 = customfield_35300;
    }

    /* if (formValue.eteam !== null && formValue.eteam !== undefined && formValue.eteam != "") {
       let customfield_72000Array: any[] = [];
       let eteamValue: any[] = [];
       eteamValue = formValue.eteam;
       eteamValue.forEach(element => {
         let customfield_72000 = { value: element };
         customfield_72000Array.push(customfield_72000);
       });
 
       fields.customfield_72000 = customfield_72000Array;
     } */

    if (formValue.pname !== null && formValue.pname !== undefined && formValue.pname != "") {
      let customfield_26309 = formValue.pname;
      fields.customfield_26309 = customfield_26309;
    }


    if (formValue.newhireequipment !== null && formValue.newhireequipment !== undefined && formValue.newhireequipment != "") {
      let customfield_72009 = { value: formValue.newhireequipment };
      fields.customfield_72009 = customfield_72009;
    }

    if (formValue.engEquipment !== null && formValue.engEquipment !== undefined && formValue.engEquipment != "") {
      let customfield_72012 = { value: formValue.engEquipment };
      fields.customfield_72012 = customfield_72012;
    }

    if (formValue.ejustification !== null && formValue.ejustification !== undefined && formValue.ejustification != "") {
      let customfield_72011 = formValue.ejustification;
      fields.customfield_72011 = customfield_72011;
    }

    if (formValue.newhireVDI !== null && formValue.newhireVDI !== undefined && formValue.newhireVDI != "") {
      let customfield_72001 = { value: formValue.newhireVDI };
      fields.customfield_72001 = customfield_72001;
    }

    /*   if (formValue.vdi !== null && formValue.vdi !== undefined && formValue.vdi != "") {
         let customfield_72002 = formValue.vdi ;
         fields.customfield_72002 = customfield_72002;
       } */

    if (formValue.newhireaws !== null && formValue.newhireaws !== undefined && formValue.newhireaws != "") {
      let customfield_72003 = { value: formValue.newhireaws };
      fields.customfield_72003 = customfield_72003;
    }

    if (formValue.awsaccountno !== null && formValue.awsaccountno !== undefined && formValue.awsaccountno != "") {
      let customfield_16101 = formValue.awsaccountno;
      fields.customfield_16101 = customfield_16101;
    }

    if (formValue.awsaccountname !== null && formValue.awsaccountname !== undefined && formValue.awsaccountname != "") {
      let customfield_49100 = formValue.awsaccountname;
      fields.customfield_49100 = customfield_49100;
    }

    if (formValue.awsrole !== null && formValue.awsrole !== undefined && formValue.awsrole != "") {
      let customfield_52802 = formValue.awsrole;
      fields.customfield_52802 = customfield_52802;
    }

    /*if (formValue.awsowner !== null && formValue.awsowner !== undefined && formValue.awsowner != "") {
      let customfield_42908 =  {name : formValue.awsowner } ;
      fields.customfield_42908 = customfield_42908;
    }*/

    if (this.userId !== null && this.userId !== undefined && this.userId != "") {
      let customfield_42908 = { name: this.userId };
      fields.customfield_42908 = customfield_42908;
    }

    if (formValue.newhiregitlab !== null && formValue.newhiregitlab !== undefined && formValue.newhiregitlab != "") {
      let customfield_72006 = { value: formValue.newhiregitlab };
      fields.customfield_72006 = customfield_72006;
    }

    if (formValue.gitlabgroup !== null && formValue.gitlabgroup !== undefined && formValue.gitlabgroup != "") {
      let customfield_72007 = { value: formValue.gitlabgroup };
      fields.customfield_72007 = customfield_72007;
    }

    if (formValue.gitlabrole !== null && formValue.gitlabrole !== undefined && formValue.gitlabrole != "") {
      let customfield_72008 = { value: formValue.gitlabrole };
      fields.customfield_72008 = customfield_72008;
    }


    if (formValue.additionalapps !== null && formValue.additionalapps !== undefined && formValue.additionalapps != "") {
      let description = 'New Hire Information for ' + this.fullName + ' \n ' + formValue.additionalapps;
      fields.description = description;
    }

    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let customfield_66000: string = "On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    fields.customfield_66000 = customfield_66000;
    localStorage.setItem("audit", customfield_66000);
    console.log('Fields ', fields);
    return fields;
  }


  formAuditPayload() {
    let fields: any = {};
    let loginUser = localStorage.getItem("username");
    let previousAudit = localStorage.getItem("audit");
    let systemDate = new Date();
    let assigneeAudit = " On " + systemDate + " , " + loginUser + " has assigned the ticket to IT Account Management.";
    let transitionAudit = " On " + systemDate + " , " + loginUser + " has transitioned the status of the ticket from Open to Pending Account Creation."
    let customfield_66000: String = previousAudit + transitionAudit + assigneeAudit;
    fields.customfield_66000 = customfield_66000;
    console.log('Fields ', fields);
    return fields;
  }

  getUserIdFromName(event: any) {
    if (event !== "" && event !== null && event !== undefined) {
      let name = event.target?.value;
      if (this.ownersList !== null && this.ownersList !== undefined && this.ownersList?.length > 0 && name !== '' && name !== null && name !== undefined) {
        this.ownersList.forEach(element => {
          if (element.displayName?.toString() === name?.toString()) {
            this.userId = element.name;
            exit;
          }
        });
      }
    }
  }

  /*
    
     getUsers(input: string) {
        this.users = [];
        const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password)
          .set('input', input)
        const headers = {
          'Access-Control-Allow-Origin': '*'
        };
        this.http.get<any>(environment.api + "users", { params, headers })
          .subscribe({
            next: data => {
              this.users = data?.users?.users;
              console.log('User is ', this.users);
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      } 
    
      getAWSOwners() {
        this.awsowners = [];
        const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password)   
        const headers = {
          'Access-Control-Allow-Origin': '*'
        };
        this.http.get<any>(environment.api + "users/awsowners", { params, headers })
          .subscribe({
            next: data => {         
              this.ownersList = data?.values;
              console.log('ownersList ', this.ownersList);
              if (this.ownersList !== null && this.ownersList !== undefined && this.ownersList?.length > 0) {
                  this.ownersList.forEach( element  => {
                    if (element.displayName !== null && element.displayName !== undefined && element.active) {
                      this.awsowners.push(element.displayName);
                    }
               }); 
              }       
              console.log('AWS Owners ', this.awsowners);
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      } 
    
    
      getDropdownValues() {
        this.workSpaceLocation = [];
        const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password);
        const headers = {
          'Access-Control-Allow-Origin': '*'
        };
    
        this.http.get<any>(environment.api + "dropdown", { params, headers })
          .subscribe({
            next: data => {
    
            const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {


            // Hire Type         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_35300" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const hireTypeList: any[] = element.allowedValues;
                  hireTypeList.forEach(item => {
                    this.hireType.push(item.value);
                  })
                }
              });
            }


            // Workspace Location      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceLocationList: any[] = element.allowedValues;
                  workSpaceLocationList.forEach(item => {
                    this.workSpaceLocation.push(item.value);
                  })
                }
              });
            }


            // Deliver Equipment To
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26119" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const deliverEquipmentList: any[] = element.allowedValues;
                  deliverEquipmentList.forEach(item => {
                    this.deliverEquipment.push(item.value);
                  })
                }
              });
            }

            // Engineering Computing Equipment
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72012" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const engComputingDropdownList: any[] = element.allowedValues;
                  engComputingDropdownList.forEach(item => {
                    this.engComputingDropdown.push(item.value);
                  })
                }
              });
            }

            // GitLab Group
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72007" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const gitLabGroupDropdownist: any[] = element.allowedValues;
                  gitLabGroupDropdownist.forEach(item => {
                    this.gitLabGroupDropdown.push(item.value);
                  })
                }
              });
            }


            // GitLab Role
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_72008" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const gitLabRoleDropdownList: any[] = element.allowedValues;
                  gitLabRoleDropdownList.forEach(item => {
                    this.gitLabRoleDropdown.push(item.value);
                  })
                }
              });
            }

          }

    
              console.log('Hire Type ', this.hireType);      
              console.log('WorkSpace Location ', this.workSpaceLocation);        
              console.log('Deliver Equipment To ', this.deliverEquipment);
              console.log('Engineering Computing Equipment ', this.engComputingDropdown);
              console.log('Gitlab Group ', this.gitLabGroupDropdown);
              console.log('Gitlab Role ', this.gitLabRoleDropdown);
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      } 
    
    
        updateStatus() {
        const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password)
          .set('ticket', this.ticket)
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };
        let transition = {
          "id": "41"
        };
        this.http.post<any>(environment.api + "onboarding/transition", JSON.stringify({ transition }), { params, headers })
          .subscribe({
            next: data => {
              console.log('Data is ', data);
              if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {
                this.pushAuditDetailsToJIRA();
                alert("Onboarding Details Submited Successfully"); 
                this.router.navigate(['../../hire'], { relativeTo: this.route });
              } else {
                alert("An Error occured while Transitioning the ticket status to Pending Account Creation."); 
              }
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      } 
    
    
      pushDetailsToJIRA(formValues: any) {
        const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password)
          .set('ticket', this.ticket)
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };
        let fields = this.formPayload(formValues);
        this.http.put<any>(environment.api + "onboarding/edit123", JSON.stringify({ fields }), { params, headers })
          .subscribe({
            next: data => {
              console.log('Data is ', data);
              if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {
                  this.updateStatus();   
             } else {
                 alert("An Error occured while submitting new hire details to Jira.");             
             }
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      }  
      
       pushAuditDetailsToJIRA() {
          const params = new HttpParams()
          .set('username', this.user)
          .set('password', this.password)
          .set('ticket', this.ticket)
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        };
        let fields = this.formAuditPayload();
        this.http.put<any>(environment.api + "onboarding/edit", JSON.stringify({ fields }), { params, headers })
          .subscribe({
            next: data => {
              console.log('Data is ', data);
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })
      
        }  
    */

}
