import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LandingPageComponent} from './Landing_Page/landing-page.component';
import {HireDashboardComponent} from './Hire_Dashboard/hire-dashboard.component';
import {HireDashboardEditComponent} from './Hire_Dashboard/Hire_Dashboard_Edit/hire-dashboard-edit.component';
import {HireDashboardEngEditComponent} from './Hire_Dashboard/Hire_Dashboard_Eng_Edit/hire-dashboard-eng-edit.component';
import {HireDashboardWithdrawComponent} from './Hire_Dashboard/Hire_Dashboard_Withdraw/hire-dashboard-withdraw.component';
import { HireDashboardDelegateManagerComponent } from './Hire_Dashboard/Hire_Dashboard_Delegate_Manager/hire-dashboard-delegate-manager.component'
import {TransferDashboardComponent} from './Transfer_Dashboard/transfer-dashboard.component';
import {TransferDashboardEditComponent} from './Transfer_Dashboard/Transfer_Dashboard_Edit/transfer-dashboard-edit.component';
import { TerminateDashboardComponent } from './Terminate_Dashboard/terminate-dashboard.component'
import { TerminateDashboardEditComponent } from './Terminate_Dashboard/Terminate_Dashboard_Edit/terminate-dashboard-edit.component'
import {TerminateDashboardWithdrawComponent} from './Terminate_Dashboard/Terminate_Dashboard_Withdraw/terminate-dashboard-withdraw.component';
import { TerminateDashboardDelegateManagerComponent } from './Terminate_Dashboard/Terminate_Dashboard_Delegate_Manager/terminate-dashboard-delegate-manager.component'
import { vendoronboardingPageComponent } from './vendor_onboarding/vendor-onboarding-page.component'
import { AuthGuard } from './authenticate/auth.guard';
import { LoginComponent } from './authenticate/login.component';
 


const routes: Routes = [
 { path: '', component: LandingPageComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'ejourney', component: LandingPageComponent , canActivate: [AuthGuard]},
  { path: 'hire', component: HireDashboardComponent , canActivate: [AuthGuard]},
  { path: 'hire-edit/:id', component: HireDashboardEditComponent, canActivate: [AuthGuard]} ,
  { path: 'hire-engineering-edit/:id', component: HireDashboardEngEditComponent, canActivate: [AuthGuard]} ,
  { path: 'hire-withdraw/:id', component: HireDashboardWithdrawComponent, canActivate: [AuthGuard]} ,
  { path: 'hire-delegate-manager/:id', component: HireDashboardDelegateManagerComponent, canActivate: [AuthGuard]} ,
  { path: 'transfer', component: TransferDashboardComponent, canActivate: [AuthGuard]},
  { path: 'current-manager-transfer-edit/:id', component: TransferDashboardEditComponent, canActivate: [AuthGuard]} ,
  { path: 'new-manager-transfer-edit/:id', component: TransferDashboardEditComponent, canActivate: [AuthGuard]} ,
  { path: 'terminate', component: TerminateDashboardComponent ,  canActivate: [AuthGuard]},
  { path: 'manager-terminate-edit/:id', component: TerminateDashboardEditComponent,  canActivate: [AuthGuard]}   ,
  { path: 'employee-terminate-edit/:id', component: TerminateDashboardEditComponent,  canActivate: [AuthGuard]}   ,
  { path: 'terminate-withdraw/:id', component: TerminateDashboardWithdrawComponent,  canActivate: [AuthGuard]} ,
  { path: 'terminate-delegate-manager/:id', component: TerminateDashboardDelegateManagerComponent,  canActivate: [AuthGuard]} ,
  { path: 'onboard-vendor', component: vendoronboardingPageComponent,  canActivate: [AuthGuard]},
]; 


/*
const routes: Routes = [
  { path: '', component: LandingPageComponent},
  { path: 'login', component: LoginComponent },
  { path: 'ejourney', component: LandingPageComponent},
  { path: 'hire', component: HireDashboardComponent },
  { path: 'hire-edit/:id', component: HireDashboardEditComponent} ,
  { path: 'hire-engineering-edit/:id', component: HireDashboardEngEditComponent} ,
  { path: 'hire-withdraw/:id', component: HireDashboardWithdrawComponent} ,
  { path: 'hire-delegate-manager/:id', component: HireDashboardDelegateManagerComponent} ,
  { path: 'transfer', component: TransferDashboardComponent},
  { path: 'current-manager-transfer-edit/:id', component: TransferDashboardEditComponent} ,
  { path: 'new-manager-transfer-edit/:id', component: TransferDashboardEditComponent} ,
  { path: 'terminate', component: TerminateDashboardComponent },
  { path: 'manager-terminate-edit/:id', component: TerminateDashboardEditComponent}   ,
  { path: 'employee-terminate-edit/:id', component: TerminateDashboardEditComponent}   ,
  { path: 'terminate-withdraw/:id', component: TerminateDashboardWithdrawComponent} ,
  { path: 'terminate-delegate-manager/:id', component: TerminateDashboardDelegateManagerComponent} ,
  { path: 'onboard-vendor', component: vendoronboardingPageComponent},
  
];
*/



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

