import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'


interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'terminate-dashboard.component',
  templateUrl: './terminate-dashboard.component.html',
  styleUrls: ['./terminate-dashboard.component.css']
})
export class TerminateDashboardComponent implements OnInit {
  finalTickets: any[] = [];
  ticketResult: any[] = [];
  ticketCount: number = 0;
  errorMessage: any;
  ticket: string = "";
  p: Number = 1;
  count: Number = 10;
  checkStatus = "Open";
  isOpen = true;
  user: any;
  password: any;
  adminView: any;
  dueTickets: string = "";
  auth: any;
  tier1: any;
  manager_group: any;

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.user = localStorage.getItem("loginUser")?.toString().toLowerCase(); 
    this.password = localStorage.getItem("loginPassword");
    this.adminView = localStorage.getItem("adminMode");
    this.auth = localStorage.getItem("Auth");
    this.tier1 = localStorage.getItem("tier1");
    this.manager_group = localStorage.getItem("manager_group");
    this.dueTickets = "";
    /* this.isOpen = true;
     if (this.adminView === 'yes') {
       this.checkStatus = "All";
     } else {
       this.checkStatus = "Open";
     } */
    this.checkStatus = "Open";
    this.getTickets(this.checkStatus, "All");
  }

  checkTerminationDate(terminationDate : any) {
    console.log('Termination Date ', new Date (terminationDate));
    console.log('Current Date ',  new Date());
    if (new Date (terminationDate) < new Date()) {
      return true;
    } else {
      return false;
    }
  }


  sortData(sort: Sort) {
    const data = this.ticketResult.slice();
    if (!sort.active || sort.direction === '') {
      this.finalTickets = data;
      return;
    }
    this.finalTickets = this.ticketResult.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ticket': return compare(a.key, b.key, isAsc);
        case 'fname': return compare(a.fields?.customfield_10659, b.fields?.customfield_10659, isAsc);
        case 'lname': return compare(a.fields?.customfield_10660, b.fields?.customfield_10660, isAsc);
        case 'email': return compare(a.fields?.customfield_10668, b.fields?.customfield_10668, isAsc);
        case 'id': return compare(a.fields?.customfield_31900, b.fields?.customfield_31900, isAsc);
        case 'wclass': return compare(a.fields?.customfield_26136?.value, b.fields?.customfield_26136?.value, isAsc);
        case 'status': return compare(a.fields?.status?.name, b.fields?.status?.name, isAsc);
        case 'manager': return compare(a.fields?.customfield_15400?.displayName, b.fields?.customfield_15400?.displayName, isAsc);
        case 'delegate-manager': return compare(a.fields?.customfield_66300?.displayName, b.fields?.customfield_66300?.displayName, isAsc);
        case 'created': return compare(a.fields.created, b.fields.created, isAsc);
        case 'duedate': return compare(a.fields.customfield_66502, b.fields.customfield_66502, isAsc);
        default: return 0;
      }
    });
  }

  searchTickets() {
    this.finalTickets = [];
    if (this.ticket !== null && this.ticket !== undefined && this.ticket !== "" &&
      this.ticketResult !== undefined && this.ticketResult !== null && this.ticketResult.length > 0) {
      this.ticketResult.forEach(a => {
        if ((this.ticket === a?.key ||
          this.ticket.toLowerCase().includes(a?.fields?.customfield_10659?.toLowerCase()) ||
          this.ticket.toLowerCase().includes(a?.fields.customfield_10660?.toLowerCase())) && (this.checkStatus === a?.fields?.status?.name || this.checkStatus === 'All')) {
          this.finalTickets.push(a);
        }
      });
    } else {
      this.ticketResult.forEach(a => {
        if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
          a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {
          this.finalTickets.push(a);
        }
      });
    }
  }

  getTicketsByDue(due: string) {
    this.dueTickets = due;
    this.getTickets(this.checkStatus, this.dueTickets);
  }


 getTickets(status: string, due: string) {
    this.auth = localStorage.getItem("Auth");
    if (this.dueTickets !== null && this.dueTickets !== undefined && this.dueTickets !== "") {
      due = this.dueTickets;
    }
    var fourDaysPastCurrentDate = new Date();
    fourDaysPastCurrentDate.setDate(fourDaysPastCurrentDate.getDate() + 4);

    if (status === 'Open') {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
    this.checkStatus = status;
    this.finalTickets = [];
    const headers = {
      "Authorization": 'Basic ' + this.auth,
      "Access-Control-Allow-Origin": "*"
    };
    let params = '';
    if (this.checkStatus !== 'All') {
      if (this.adminView !== 'yes') {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = offboarding AND ("Delegate Manager" = ' + '"' + this.user + '"' + ' OR Manager =' + '"' + this.user + '" OR "Employee Login ID" ~ ' + '"' + this.user  + '")' + ' AND status = ' + '"' + status + '"' + ' ORDER BY  "Employee Termination Date"   &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,customfield_31900,customfield_66502,customfield_66801,customfield_67200,customfield_41805,duedate,created ';
      } else {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Offboarding AND status = ' + '"' + this.checkStatus + '"' + ' ORDER BY   "Employee Termination Date"   &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,customfield_31900,customfield_66502,customfield_66801,customfield_67200,customfield_41805,duedate,created ';
      }
    } else {
      if (this.adminView !== 'yes') {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = offboarding AND ("Delegate Manager" = ' + '"' + this.user + '"' + ' OR Manager =' + '"' + this.user + '" OR "Employee Login ID" ~ ' + '"' + this.user  + '")' + ' ORDER BY  "Employee Termination Date"  &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,customfield_31900,customfield_66502,customfield_66801,customfield_67200,customfield_41805,duedate,created ';
      } else {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Offboarding ORDER BY   "Employee Termination Date"   &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,customfield_31900,customfield_66502,customfield_66801,customfield_67200,customfield_41805,duedate,created';
      }
    }
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.ticketCount = data.total;
          this.ticketResult = data.issues;
          localStorage.setItem("AllTickets", JSON.stringify(this.ticketResult));
          this.ticketResult.forEach(a => {
            a.fields.customfield_31900 = a.fields.customfield_31900?.toString().toLowerCase();
            console.log('Employee Login ID ', a.fields.customfield_31900);
            if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
              a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {                 
              
             if (this.checkStatus  === 'Open'  && ((this.user === a.fields.customfield_31900 && (a.fields.customfield_41805 === null || a.fields.customfield_41805 === undefined || a.fields.customfield_41805 === '')) ||
                ((this.user === a.fields.customfield_15400?.name || this.user === a.fields.customfield_66300?.name) && (a.fields.customfield_66801 === null || a.fields.customfield_66801 === undefined || a.fields.customfield_66801 === ''))) ||
                this.checkStatus  !== 'Open' || this.adminView === 'yes') {
                a.fields.alert = "white";
                if (a.fields.status.name !== 'Closed' && a.fields.status.name !== 'Withdrawn') {
                  if (a.fields.customfield_66502 !== null && a.fields.customfield_66502 !== undefined && a.fields.customfield_66502 !== '' && new Date(a.fields.customfield_66502) < new Date()) {
                    a.fields.alert = "red";
                  } else if (a.fields.customfield_66502 !== null && a.fields.customfield_66502 !== undefined && a.fields.customfield_66502 !== '' && new Date(a.fields.customfield_66502) <= fourDaysPastCurrentDate) {
                    a.fields.alert = "yellow";
                  } else {
                    a.fields.alert = "green";
                  }
                }
              if (this.checkStatus !== 'Closed' && this.checkStatus !== 'Withdrawn') {
                if (due === 'Overdue') {
                  if (a.fields.alert === "red") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duewithin5') {
                  if (a.fields.alert === "yellow") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duemorethan5') {
                  if (a.fields.alert === "green") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'All') {
                  this.finalTickets.push(a);
                }
              } else {
                this.finalTickets.push(a);
              }
            }
           }
          });
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  } 
/*
  getTickets(status: string, due: string) {
    if (this.dueTickets !== null && this.dueTickets !== undefined && this.dueTickets !== "") {
      due = this.dueTickets;
    }
    var fourDaysPastCurrentDate = new Date();
    fourDaysPastCurrentDate.setDate(fourDaysPastCurrentDate.getDate() + 4);

    if (status === 'Open') {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
    this.checkStatus = status;
    this.finalTickets = [];
    const params = new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('status', this.checkStatus)
      .set('admin', this.adminView);
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };
    this.http.get<any>(environment.api + "Offboarding", { params, headers })
      .subscribe({
        next: data => {
          this.ticketCount = data.total;
          this.ticketResult = data.issues;
          localStorage.setItem("AllTickets", JSON.stringify(this.ticketResult));
          this.ticketResult.forEach(a => {
            a.fields.customfield_31900 = a.fields.customfield_31900?.toString().toLowerCase();
            console.log('Employee Login ID ', a.fields.customfield_31900);
            if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
              a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {                 
              
             if (this.checkStatus  === 'Open'  && ((this.user === a.fields.customfield_31900 && (a.fields.customfield_41805 === null || a.fields.customfield_41805 === undefined || a.fields.customfield_41805 === '')) ||
                ((this.user === a.fields.customfield_15400?.name || this.user === a.fields.customfield_66300?.name) && (a.fields.customfield_66801 === null || a.fields.customfield_66801 === undefined || a.fields.customfield_66801 === ''))) ||
                this.checkStatus  !== 'Open' || this.adminView === 'yes') {
                a.fields.alert = "white";
                if (a.fields.status.name !== 'Closed' && a.fields.status.name !== 'Withdrawn') {
                  if (a.fields.customfield_66502 !== null && a.fields.customfield_66502 !== undefined && a.fields.customfield_66502 !== '' && new Date(a.fields.customfield_66502) < new Date()) {
                    a.fields.alert = "red";
                  } else if (a.fields.customfield_66502 !== null && a.fields.customfield_66502 !== undefined && a.fields.customfield_66502 !== '' && new Date(a.fields.customfield_66502) <= fourDaysPastCurrentDate) {
                    a.fields.alert = "yellow";
                  } else {
                    a.fields.alert = "green";
                  }
                }
                if (this.checkStatus !== 'Closed' && this.checkStatus !== 'Withdrawn') {
                  if (due === 'Overdue') {
                    if (a.fields.alert === "red") {
                      this.finalTickets.push(a);
                    }
                  } else if (due === 'Duewithin5') {
                    if (a.fields.alert === "yellow") {
                      this.finalTickets.push(a);
                    }
                  } else if (due === 'Duemorethan5') {
                    if (a.fields.alert === "green") {
                      this.finalTickets.push(a);
                    }
                  } else if (due === 'All') {
                    this.finalTickets.push(a);
                  }
                } else {
                  this.finalTickets.push(a);
                }
              }
            }
          });
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  } */

} 


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}