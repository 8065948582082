import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import * as moment from 'moment';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

@Component({
  selector: 'landing-page.component',
  templateUrl: './terminate-dashboard-edit.component.html',
  styleUrls: ['./terminate-dashboard-edit.component.css']
})
export class TerminateDashboardEditComponent implements OnInit {

  offboardingForm: FormGroup;
  offboardingEmployeeForm: FormGroup;
  salesflag: boolean = false;
  editorialflag: boolean = false;
  additionalflag: boolean = false;
  ticket: any;
  employeeName: any;
  employeeId: any;
  lastdate: any;
  data: any[] = [];
  users: any[] = [];
  errorMessage: any;
  workSpaceLocation: any[] = [];
  equipmentCollection: any[] = [];
  deviceType: any[] = [];
  rushRequest: any[] = [];
  //  salesEmployeeType: any[] = [];
  equipmentReturn: any[] = [];
  user: any;
  password: any;
  auth: any;
  showReason: boolean = false;
  workerClass: any;
  manager: any;
  employee: any;
  devicetype_dropdownList: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.offboardingForm = this.formBuilder.group({
      //   managerphoneno: [null],
      //   existingphoneno: [null, Validators.required],
      //   existingemail: [null, Validators.required],
      //   location: ["", Validators.required],
      //   workspaceno: [null, Validators.required],
      //   devicetype: ["", Validators.required],
      emailforwarding: ["", Validators.required],
      dataAccess: ["", Validators.required],
      //    rushrequest: ["", Validators.required],
      //    additionalapps: [null],
      //   shippingaddress: [null, Validators.required],
      facilitiesadditional: [null, Validators.required],
      reason: [null],
      //   salesemployeetype: [""],
      //   lastdate: ["", Validators.required],
    });

    this.offboardingEmployeeForm = this.formBuilder.group({
      //    existingphoneno: [null, Validators.required],
      //    existingemail: [null, Validators.required], 
      devicetype: ["", Validators.required],
      employeeadditionalapps: [null],
      //    shippingaddress: [null, Validators.required],
      equipmentreturn: ["", Validators.required],
      //  salesemployeetype: [""],

    });
  }

  ngOnInit() {


    /*  this.deviceType = [
       { item_id: '102200', item_text: 'BBVPN Device' },
       { item_id: '102201', item_text: 'BUnit' },
       { item_id: '102202', item_text: 'iPad' },
       { item_id: '102203', item_text: 'Jetpack/MiFi' },
       { item_id: '102204', item_text: 'Laptop - INDG Issued' },
       { item_id: '102205', item_text: 'MacBook - INDG Issued' },
       { item_id: '102206', item_text: 'Mobile Phone - Android' },
       { item_id: '102207', item_text: 'Mobile Phone - iPhone' },
       { item_id: '102208', item_text: 'Tablet' },
       { item_id: '102209', item_text: 'Credit Card (AMEX)' },
       { item_id: '102210', item_text: 'Credit Card (BOA/PCard)' },
       { item_id: '102211', item_text: 'Badge - INDG' },
       { item_id: '102212', item_text: 'Badge - Black Badge' },
       { item_id: '102213', item_text: 'Parking - Hang Tag' },
       { item_id: '102214', item_text: 'Parking - Access Pass' },
       { item_id: '102215', item_text: 'Cabinet Keys' }
     ];*/

    this.deviceType = [
      'BBVPN Device',
      'BUnit',
      'iPad',
      'Jetpack/MiFi',
      'Laptop - INDG Issued',
      'MacBook - INDG Issued',
      'Mobile Phone - Android',
      'Mobile Phone - iPhone',
      'Tablet',
      'Credit Card (AMEX)',
      'Credit Card (BOA/PCard)',
      'Parking - INDG Parking Pass & Hang Tag',
      'Contact Tracing - Instant Trace Tag ',
      'Badge - INDG Security Badge',
      'Fob - K St Office Fob',
      'Keys - Desk/Cabinet Keys',
      'Press Pass - Senate Gallery Press Pass'
    ];
    this.submitted = false;
    this.showReason = false;
    this.offboardingForm.controls['facilitiesadditional'].setValidators([Validators.required,Validators.maxLength(255)]);   
    localStorage.setItem("audit", '');
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("edit/");
    if (str[0].includes('employee')) {
      this.employee = true;
      this.manager = false;
    } else if (str[0].includes('manager')) {
      this.employee = false;
      this.manager = true;
    } else {
      this.employee = false;
      this.manager = false;
    }
    console.log('Employee ', this.employee);
    console.log('Manager ', this.manager);
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          this.employeeName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.employeeId = a.fields.customfield_31900;
          let hrLastdate = a.fields.customfield_66502;
          if (hrLastdate !== null && hrLastdate !== undefined && hrLastdate !== '') {
            this.lastdate = moment(hrLastdate).format("MM/DD/YYYY");
          }
          let worker = a.fields.customfield_26136;
          this.workerClass = worker.value;
          console.log('Worker Class ', this.workerClass);
          console.log('Last Date ', this.lastdate);
          /*    if (this.workerClass === 'Sales Representative') {
                this.offboardingEmployeeForm.controls['salesemployeetype'].setValidators(Validators.required);
              }
              else {
                this.offboardingEmployeeForm.controls['salesemployeetype'].clearValidators();
                this.offboardingEmployeeForm.controls['salesemployeetype'].updateValueAndValidity();
              } */
        }
      });
    }
    this.getDropdownValues();
    this.dropdownSettings = {
      //  idField: 'item_id',
      //  textField: 'item_text',
      allowSearchFilter: true,
    };

  }

  getDataAccess(event: any) {
    console.log('Event ', event);
    console.log('Data Access ', this.offboardingForm.controls['dataAccess'].value);
    console.log('Email ', this.offboardingForm.controls['emailforwarding'].value);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes') || (this.offboardingForm.controls['emailforwarding'].value == 'Yes')) {
      this.showReason = true;
      this.offboardingForm.controls['reason'].setValidators(Validators.required);
    }
    else {
      this.showReason = false;
      this.offboardingForm.controls['reason'].clearValidators();
      this.offboardingForm.controls['reason'].updateValueAndValidity();
    }
  }

  getEmailDataAccess(event: any) {
    console.log('Data Access ', this.offboardingForm.controls['dataAccess'].value);
    console.log('Email ', this.offboardingForm.controls['emailforwarding'].value);
    console.log('Event ', event);
    if ((event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes') || (this.offboardingForm.controls['dataAccess'].value == 'Yes')) {
      this.showReason = true;
      this.offboardingForm.controls['reason'].setValidators(Validators.required);
    }
    else {
      this.showReason = false;
      this.offboardingForm.controls['reason'].clearValidators();
      this.offboardingForm.controls['reason'].updateValueAndValidity();
    }
  }

  // System Acccess
  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any) {
    console.log('onItemDeSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  onUnSelectAll() {
    console.log('onUnSelectAll fires');
  }

  onSubmit(): void {
    console.warn('Form Values', this.offboardingForm.value);
    console.log(this.offboardingForm.valid);
    if (this.offboardingForm.valid) {
      this.submitted = true;
      //   alert("It might take upto few minutes to push details to Jira, please do not resubmitted or refresh your browser");
      this.pushDetailsToJIRA(this.offboardingForm.value);
    }
  }

  onEmployeeSubmit(): void {
    console.warn('Form Values', this.offboardingEmployeeForm.value);
    console.log(this.offboardingEmployeeForm.valid);
    if (this.offboardingEmployeeForm.valid) {
      this.submitted = true;
      this.pushDetailsToJIRA(this.offboardingEmployeeForm.value);
    }
  }


  getUsers(input: string) {
    this.auth = localStorage.getItem("Auth");
    this.users = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'groupuserpicker?query=' + input;
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.users = data?.users?.users;
          console.log('User is ', this.users);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }

  getDropdownValues() {
    this.auth = localStorage.getItem("Auth");
    this.workSpaceLocation = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'issue/createmeta/HRSD/issuetypes/18100?maxResults=100';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {


          const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // Workspace Location        
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const workSpaceLocationList: any[] = element.allowedValues;
                workSpaceLocationList.forEach(item => {
                  this.workSpaceLocation.push(item.value);
                })
              }
            });

            // Eqiupment Return Method
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_41805" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const equipmentReturnList: any[] = element.allowedValues;
                equipmentReturnList.forEach(item => {
                  this.equipmentReturn.push(item.value);
                })
              }
            });

            // Rush Request
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_19618" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const rushRequestList: any[] = element.allowedValues;
                rushRequestList.forEach(item => {
                  this.rushRequest.push(item.value);
                })
              }
            });


          }

          // Device Type
          //   const deviceTypeList: any[] = data.projects[0].issuetypes[0].fields?.customfield_66900?.allowedValues;
          //   if (deviceTypeList !== null && deviceTypeList !== undefined && deviceTypeList.length > 0) {
          //     deviceTypeList.forEach(element => {
          //       if (element !== null && element !== undefined && element !== "" && element.value !== null && element.value !== undefined && element.value !== ""  && element.disabled == false) {
          //         let device =  { item_id: element.value, item_text: element.value };
          //        this.devicetype_dropdownList.push(device);
          //      }
          //    });
          //  }



          // Sales Employee Type
          /*      const salesEmployeeTypeList: any[] = data.projects[0].issuetypes[0].fields?.customfield_67000?.allowedValues;
                if (salesEmployeeTypeList !== null && salesEmployeeTypeList !== undefined && salesEmployeeTypeList.length > 0) {
                  salesEmployeeTypeList.forEach(element => {
                    if (element !== null && element !== undefined && element !== "" && element.value !== null && element.value !== undefined && element.value !== "" && element.disabled == false) {
                      this.salesEmployeeType.push(element.value);
                    }
                  });
                } */







          console.log('WorkSpace Location ', this.workSpaceLocation);
          console.log('Equipment Collection ', this.equipmentCollection);
          console.log('Rush Request ', this.rushRequest);

        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }



  pushDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            this.updateStatus();
          } else {
            alert("An Error occured while submitting Offboarding details to Jira, please find the error " + JSON.stringify(data?.errors));
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }



  updateStatus() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket + '/transitions?expand=transitions.fields';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let transition = {
      "id": "11"
    };
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ transition }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {
            this.pushAuditDetailsToJIRA();
            alert("Offboarding Details Submited Successfully");
            this.router.navigate(['../../terminate'], { relativeTo: this.route });
          } else {
            //   console.log("An Error occured while Transitioning the ticket status to Pending Deactivations, please find the error " +  JSON.stringify(data?.errorMessages)); 
            //  alert("Offboarding Details Submited Successfully");
            this.router.navigate(['../../terminate'], { relativeTo: this.route });
          }
        },
        error: error => {
          //     this.errorMessage = error.message;
          //    console.error('Error ', error);
          //    alert("Offboarding Details Submited Successfully");
          this.router.navigate(['../../terminate'], { relativeTo: this.route });
        }
      })
  }


  pushAuditDetailsToJIRA() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formAuditPayload();
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }


  formPayload(formValue: any) {
    console.log('Payload ', formValue);
    let fields: any = {};

    if (formValue.location !== null && formValue.location !== undefined && formValue.location != "") {
      let customfield_26137 = { value: formValue.location };
      fields.customfield_26137 = customfield_26137;
    }
    if (formValue.workspaceno !== null && formValue.workspaceno !== undefined && formValue.workspaceno != "") {
      let customfield_26329 = formValue.workspaceno;
      fields.customfield_26329 = customfield_26329;
    }
    if (formValue.managerphoneno !== null && formValue.managerphoneno !== undefined && formValue.managerphoneno != "") {
      let customfield_26320 = formValue.managerphoneno;
      fields.customfield_26320 = customfield_26320;
    }
    if (formValue.existingphoneno !== null && formValue.existingphoneno !== undefined && formValue.existingphoneno != "") {
      let customfield_26313 = formValue.existingphoneno;
      fields.customfield_26313 = customfield_26313;
    }
    if (formValue.existingemail !== null && formValue.existingemail !== undefined && formValue.existingemail != "") {
      let customfield_67200 = formValue.existingemail;
      fields.customfield_67200 = customfield_67200;
    }
    if (formValue.equipmentcollection !== null && formValue.equipmentcollection !== undefined && formValue.equipmentcollection != "") {
      let customfield_66501Array: any[] = [];
      let equipmentcollectionValue: any[] = [];
      equipmentcollectionValue = formValue.equipmentcollection;
      equipmentcollectionValue.forEach(element => {
        let customfield_66501 = { value: element };
        customfield_66501Array.push(customfield_66501);
      });
      fields.customfield_66501 = customfield_66501Array;
    }

    if (formValue.equipmentreturn !== null && formValue.equipmentreturn !== undefined && formValue.equipmentreturn != "") {
      let customfield_41805 = { value: formValue.equipmentreturn };
      fields.customfield_41805 = customfield_41805;
    }

    if (formValue.devicetype !== null && formValue.devicetype !== undefined && formValue.devicetype != "") {
      let customfield_66900Array: any[] = [];
      let devicetypeValue: any[] = [];
      devicetypeValue = formValue.devicetype;
      devicetypeValue.forEach(element => {
        let customfield_66900 = { value: element };
        customfield_66900Array.push(customfield_66900);
      });
      fields.customfield_66900 = customfield_66900Array;
    }

    /*   if (formValue.salesemployeetype !== null && formValue.salesemployeetype !== undefined && formValue.salesemployeetype != "") {
         let customfield_67000 = { value: formValue.salesemployeetype };
         fields.customfield_67000 = customfield_67000;
       }
    */
    /*  if (formValue.rushrequest !== null && formValue.rushrequest !== undefined && formValue.rushrequest != "") {
        let customfield_19618 = { value: formValue.rushrequest };
        fields.customfield_19618 = customfield_19618;
      }  */

    if (formValue.emailforwarding !== null && formValue.emailforwarding !== undefined && formValue.emailforwarding != "") {
      let customfield_66800 = { value: formValue.emailforwarding };
      fields.customfield_66800 = customfield_66800;
    }

    if (formValue.dataAccess !== null && formValue.dataAccess !== undefined && formValue.dataAccess != "") {
      let customfield_66801 = { value: formValue.dataAccess };
      fields.customfield_66801 = customfield_66801;
    }

    if (formValue.reason !== null && formValue.reason !== undefined && formValue.reason != "") {
      let customfield_59004 = formValue.reason;
      fields.customfield_59004 = customfield_59004;
    }

    if (formValue.shippingaddress !== null && formValue.shippingaddress !== undefined && formValue.shippingaddress != "") {
      let customfield_26327 = formValue.shippingaddress;
      fields.customfield_26327 = customfield_26327;
    }
    if (formValue.additionalapps !== null && formValue.additionalapps !== undefined && formValue.additionalapps != "") {
      let customfield_31103 = formValue.additionalapps;
      fields.customfield_31103 = customfield_31103;
    }

    if (formValue.employeeadditionalapps !== null && formValue.employeeadditionalapps !== undefined && formValue.employeeadditionalapps != "") {
      let customfield_67201 = formValue.employeeadditionalapps;
      fields.customfield_67201 = customfield_67201;
    }
    /*   if (formValue.lastdate !== null && formValue.lastdate !== undefined && formValue.lastdate != "") {  
         let customfield_66502 : Date = new Date(formValue.lastdate);
         fields.customfield_66502 = customfield_66502;
         console.log('Last Date ', customfield_66502);
       } */
    if (formValue.facilitiesadditional !== null && formValue.facilitiesadditional !== undefined && formValue.facilitiesadditional != "") {
      let customfield_66901 = formValue.facilitiesadditional;
      fields.customfield_66901 = customfield_66901;
    }



    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let customfield_66000: string = "On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    fields.customfield_66000 = customfield_66000;
    localStorage.setItem("audit", customfield_66000);
    console.log('Fields ', fields);
    return fields;
  }


  formAuditPayload() {
    let fields: any = {};
    let loginUser = localStorage.getItem("username");
    let previousAudit = localStorage.getItem("audit");
    let systemDate = new Date();
    let assigneeAudit = " On " + systemDate + " , " + loginUser + " has assigned the ticket to IT Account Management.";
    let transitionAudit = " On " + systemDate + " , " + loginUser + " has transitioned the status of the ticket from Open to Pending Deactivations."
    let customfield_66000: String = previousAudit + transitionAudit + assigneeAudit;
    fields.customfield_66000 = customfield_66000;
    console.log('Fields ', fields);
    return fields;
  }



  /*   getUsers(input: string) {
       this.users = [];
       const params = new HttpParams()
         .set('username', this.user)
         .set('password', this.password)
         .set('input', input)
       const headers = {
         'Access-Control-Allow-Origin': '*'
       };
       this.http.get<any>(environment.api + "users", { params, headers })
         .subscribe({
           next: data => {
             this.users = data?.users?.users;
             console.log('User is ', this.users);
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
     }
   
   
     getDropdownValues() {
       this.workSpaceLocation = [];
       const params = new HttpParams()
         .set('username', this.user)
         .set('password', this.password);
       const headers = {
         'Access-Control-Allow-Origin': '*'
       };
   
       this.http.get<any>(environment.api + "dropdown", { params, headers })
         .subscribe({
           next: data => {
   
   
       const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // Workspace Location        
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const workSpaceLocationList: any[] = element.allowedValues;
                workSpaceLocationList.forEach(item => {
                  this.workSpaceLocation.push(item.value);
                })
              }
            });

            // Eqiupment Return Method
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_41805" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const equipmentReturnList: any[] = element.allowedValues;
                equipmentReturnList.forEach(item => {
                  this.equipmentReturn.push(item.value);
                })
              }
            });

            // Rush Request
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_19618" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const rushRequestList: any[] = element.allowedValues;
                rushRequestList.forEach(item => {
                  this.rushRequest.push(item.value);
                })
              }
            });


          }
   
   
             console.log('WorkSpace Location ', this.workSpaceLocation);            
              console.log('Equipment Return Method ', this.equipmentReturn); 
             console.log('Rush Request ', this.rushRequest);
   
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
     } 
   
   
     updateStatus() {
       const params = new HttpParams()
         .set('username', this.user)
         .set('password', this.password)
         .set('ticket', this.ticket)
       const headers = {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*'
       };
       let transition = {
         "id": "11"
       };
       this.http.post<any>(environment.api + "offboarding/transition", JSON.stringify({ transition }), { params, headers })
         .subscribe({
           next: data => {
             console.log('Data is ', data);
             if (data == null || data === undefined || data === '' || data?.errorMessages === null || data?.errorMessages === undefined || data?.errorMessages?.count === 0) {
               this.pushAuditDetailsToJIRA();
               alert("Offboarding Details Submited Successfully");
               this.router.navigate(['../../terminate'], { relativeTo: this.route });
             } else {
             //  alert("An Error occured while Transitioning the ticket status to Pending Deactivations, please find the error " + JSON.stringify(data?.errorMessages));
            // console.log("An Error occured while Transitioning the ticket status to Pending Deactivations, please find the error " + JSON.stringify(data?.errorMessages));  
             alert("Offboarding Details Submited Successfully");
               this.router.navigate(['../../terminate'], { relativeTo: this.route });
         
             }
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
     }
   
   
     pushDetailsToJIRA(formValues: any) {
       const params = new HttpParams()
         .set('username', this.user)
         .set('password', this.password)
         .set('ticket', this.ticket)
       const headers = {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*'
       };
       let fields = this.formPayload(formValues);
       this.http.put<any>(environment.api + "offboarding/edit", JSON.stringify({ fields }), { params, headers })
         .subscribe({
           next: data => {
             console.log('Data is ', data);
             if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
               this.updateStatus();
             } else {
               alert("An Error occured while submitting termination details to Jira, please find the error " + JSON.stringify(data?.errors));
             }
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
     }
   
     pushAuditDetailsToJIRA() {
       const params = new HttpParams()
         .set('username', this.user)
         .set('password', this.password)
         .set('ticket', this.ticket)
       const headers = {
         'Content-Type': 'application/json',
         'Access-Control-Allow-Origin': '*'
       };
       let fields = this.formAuditPayload();
       this.http.put<any>(environment.api + "offboarding/edit", JSON.stringify({ fields }), { params, headers })
         .subscribe({
           next: data => {
             console.log('Data is ', data);
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
     } */


}