import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'landing-page.component',
  templateUrl: './terminate-dashboard-delegate-manager.component.html',
  styleUrls: ['./terminate-dashboard-delegate-manager.component.css']
})
export class TerminateDashboardDelegateManagerComponent implements OnInit {

  delegateManagerForm: FormGroup;
  ticket: any;
  employeeName: any;
  employeeId: any;
  data: any[] = [];
  errorMessage: any;
  user: any;
  password: any;
  auth: any;
  users: any[] = [];
  submitted : boolean = false;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.delegateManagerForm = this.formBuilder.group({
      delegatemanager: [null]
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.delegateManagerForm.controls['delegatemanager'].setValidators([Validators.required, Validators.pattern('.*[0-9].*')]); 
    localStorage.setItem("audit", '');
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("delegate-manager/");
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          this.employeeName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.employeeId = a.fields.customfield_31900;
        }
      });
    }
  }


  onSubmit(): void {
    console.warn('Form Values', this.delegateManagerForm.value);
    console.log(this.delegateManagerForm.valid);
    if (this.delegateManagerForm.valid) {
      this.submitted = true;
      this.pushDetailsToJIRA(this.delegateManagerForm.value);
    }
  }

  
  getUsers(input: string) {
    this.auth = localStorage.getItem("Auth");
     this.users = [];
     const headers = {
       'Authorization': 'Basic ' + this.auth,
       'Access-Control-Allow-Origin': '*'
     };
     const params = 'groupuserpicker?query=' + input;
     this.http.get<any>(environment.jiraAPI + params, { headers })
       .subscribe({
         next: data => {
           this.users = data?.users?.users;
           console.log('User is ', this.users);
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })
 
   } 



  pushDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {          
            this.pushAuditDetailsToJIRA(this.delegateManagerForm.value);  
            alert("Manager Delegated Successfully");  
            this.router.navigate(['../../terminate'], { relativeTo: this.route });  
          } else {
            alert("An Error occured while delegating manager to the ticket, please find the error " +  JSON.stringify(data?.errors));     
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }



  pushAuditDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formAuditPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  } 

  formPayload(formValues: any) {
    console.log('Payload ', formValues);    
    let fields: any = {};
    if (formValues.delegatemanager !== null && formValues.delegatemanager !== undefined && formValues.delegatemanager != "") {
      let customfield_66300 = { name: formValues.delegatemanager };
    //  fields.assignee = customfield_66300; 
      fields.customfield_66300 = customfield_66300;   
     }
    console.log('Fields ', fields);
    return fields;
  } 


  formAuditPayload(formValues: any) {
    let fields: any = {};
    let loginUser = localStorage.getItem("username");
    let systemDate = new Date(); 
    let customfield_66000: String =    "On " + systemDate + " , " + loginUser + " has added delegate manager to the ticket : " + JSON.stringify(formValues.delegatemanager);
    fields.customfield_66000 = customfield_66000;
    console.log('Fields ', fields);
    return fields;
  } 


/*  getUsers(input: string) {
    this.users = [];
    const params = new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('input', input)
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };
    this.http.get<any>(environment.api + "users", { params, headers })
      .subscribe({
        next: data => {
          this.users = data?.users?.users;
          console.log('User is ', this.users);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  } 


    pushDetailsToJIRA(formValues: any) {
      const params = new HttpParams()
       .set('username', this.user)
       .set('password', this.password)
       .set('ticket', this.ticket)
     const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
     let fields = this.formPayload(formValues);
     this.http.put<any>(environment.api + "offboarding/edit", JSON.stringify({ fields }), { params, headers })
       .subscribe({
         next: data => {
           console.log('Data is ', data);
           if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {        
             this.pushAuditDetailsToJIRA(this.delegateManagerForm.value);
             alert("Manager Delegated Successfully"); 
             this.router.navigate(['../../terminate'], { relativeTo: this.route });           
           } else {
                 alert("An Error occured while delegating manager to the ticket, please find the error " +  JSON.stringify(data?.errors));             
           }  
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })
   } 
   
 
     pushAuditDetailsToJIRA(formValues: any) {
       const params = new HttpParams()
       .set('username', this.user)
       .set('password', this.password)
       .set('ticket', this.ticket)
     const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
     let fields = this.formAuditPayload(formValues);
     this.http.put<any>(environment.api + "offboarding/edit", JSON.stringify({ fields }), { params, headers })
       .subscribe({
         next: data => {
           console.log('Data is ', data);
         },
         error: error => {
           this.errorMessage = error.message;
           console.error('Error ', error);
         }
       })  
     } */
 



}