import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HireDashboardComponent } from './Hire_Dashboard/hire-dashboard.component'
import { HireDashboardEditComponent } from './Hire_Dashboard/Hire_Dashboard_Edit/hire-dashboard-edit.component'
import { HireDashboardEngEditComponent } from './Hire_Dashboard/Hire_Dashboard_Eng_Edit/hire-dashboard-eng-edit.component'
import { HireDashboardWithdrawComponent } from './Hire_Dashboard/Hire_Dashboard_Withdraw/hire-dashboard-withdraw.component'
import { HireDashboardDelegateManagerComponent } from './Hire_Dashboard/Hire_Dashboard_Delegate_Manager/hire-dashboard-delegate-manager.component'
import {TransferDashboardComponent} from './Transfer_Dashboard/transfer-dashboard.component'
import {TransferDashboardEditComponent} from './Transfer_Dashboard/Transfer_Dashboard_Edit/transfer-dashboard-edit.component'
import { TerminateDashboardComponent } from './Terminate_Dashboard/terminate-dashboard.component'
import { TerminateDashboardEditComponent } from './Terminate_Dashboard/Terminate_Dashboard_Edit/terminate-dashboard-edit.component'
import {TerminateDashboardWithdrawComponent} from './Terminate_Dashboard/Terminate_Dashboard_Withdraw/terminate-dashboard-withdraw.component';
import { TerminateDashboardDelegateManagerComponent } from './Terminate_Dashboard/Terminate_Dashboard_Delegate_Manager/terminate-dashboard-delegate-manager.component'
import { LandingPageComponent } from './Landing_Page/landing-page.component'
import { vendoronboardingPageComponent } from './vendor_onboarding/vendor-onboarding-page.component'
import { LoginComponent } from './authenticate/login.component';
import { OktaService } from './authenticate/okta.service';
import { AuthGuard } from './authenticate/auth.guard';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
 

@NgModule({
  declarations: [
    AppComponent, 
    LandingPageComponent,
    LoginComponent,
    HireDashboardComponent,
    HireDashboardEditComponent,
    HireDashboardEngEditComponent,
    HireDashboardWithdrawComponent,
    HireDashboardDelegateManagerComponent,
    TransferDashboardComponent,
    TransferDashboardEditComponent,
    TerminateDashboardComponent,
    TerminateDashboardEditComponent,
    TerminateDashboardWithdrawComponent,
    TerminateDashboardDelegateManagerComponent,
    vendoronboardingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    NgxPaginationModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  /*  BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }) */
 ],
  providers: [HttpClientModule,  OktaService,  AuthGuard],
  bootstrap: [AppComponent, HireDashboardComponent, LandingPageComponent,vendoronboardingPageComponent]
})
export class AppModule { }




