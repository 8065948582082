import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'


interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'landing-page.component',
  templateUrl: './hire-dashboard.component.html',
  styleUrls: ['./hire-dashboard.component.css']
})
export class HireDashboardComponent implements OnInit {
  vmsViewAccess: boolean = false;
  finalTickets: any[] = [];
  ticketResult: any[] = [];
  ticketCount: number = 0;
  errorMessage: any;
  ticket: string = "";
  p: Number = 1;
  count: Number = 10;
  checkStatus = "Open";
  isOpen = true;
  user: any;
  password: any;
  adminView: any;
  dueTickets :string = "";
  auth : any;
  tier1 : any;
  constructor(private http: HttpClient) {
  }

  ngOnInit() {
   // this.vmsViewAccess = true;
    this.vmsViewAccess = false;
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.adminView = localStorage.getItem("adminMode");   
    this.auth = localStorage.getItem("Auth");
    this.tier1 = localStorage.getItem("tier1");
    this.dueTickets = "";
    this.isOpen = true;
  /*  if (this.adminView === 'yes') {
      this.checkStatus = "All";
    } else {
      this.checkStatus = "Open";
    } */
    this.checkStatus = "Open";
    if (localStorage.getItem("isvms") === "yes") {
      this.vmsViewAccess = true;
    }
    this.getTickets(this.checkStatus, "All");
  }

  sortData(sort: Sort) {
    const data = this.ticketResult.slice();
    if (!sort.active || sort.direction === '') {
      this.finalTickets = data;
      return;
    }
    this.finalTickets = this.ticketResult.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ticket': return compare(a.key, b.key, isAsc);
        case 'fname': return compare(a.fields?.customfield_10659, b.fields?.customfield_10659, isAsc);
        case 'lname': return compare(a.fields?.customfield_10660, b.fields?.customfield_10660, isAsc);
        case 'demail': return compare(a.fields?.customfield_28600?.value, b.fields?.customfield_28600?.value, isAsc);
        case 'email': return compare(a.fields?.customfield_10668, b.fields?.customfield_10668, isAsc);
        case 'id': return compare(a.fields?.customfield_26328, b.fields?.customfield_26328, isAsc);
        case 'etype': return compare(a.fields?.customfield_18700?.value, b.fields?.customfield_18700?.value, isAsc);
        case 'wclass': return compare(a.fields?.customfield_26136?.value, b.fields?.customfield_26136?.value, isAsc);
        case 'dept': return compare(a.fields?.customfield_16302, b.fields?.customfield_16302, isAsc);
        case 'startdate': return compare(a.fields?.customfield_26316, b.fields?.customfield_26316, isAsc);
        case 'equipment': return compare(a.fields?.customfield_26119?.value, b.fields?.customfield_26119?.value, isAsc);
        case 'phone': return compare(a.fields?.customfield_26130?.value, b.fields?.customfield_26130?.value, isAsc);
        case 'status': return compare(a.fields?.status?.name, b.fields?.status?.name, isAsc);
        case 'manager': return compare(a.fields?.customfield_15400?.displayName, b.fields?.customfield_15400?.displayName, isAsc);
        case 'delegate-manager': return compare(a.fields?.customfield_66300?.displayName, b.fields?.customfield_66300?.displayName, isAsc);
        case 'created': return compare(a.fields.created, b.fields.created, isAsc);
        case 'duedate': return compare(a.fields.duedate, b.fields.duedate, isAsc);
        default: return 0;
      }
    });
  }

  searchTickets() {
    this.finalTickets = [];
    if (this.ticket !== null && this.ticket !== undefined && this.ticket !== "" &&
      this.ticketResult !== undefined && this.ticketResult !== null && this.ticketResult.length > 0) {
      this.ticketResult.forEach(a => {
        if ((this.ticket === a?.key ||
          this.ticket.toLowerCase().includes(a?.fields?.customfield_10659?.toLowerCase()) ||
          this.ticket.toLowerCase().includes(a?.fields.customfield_10660?.toLowerCase())) && (this.checkStatus === a?.fields?.status?.name || this.checkStatus === 'All')) {
          this.finalTickets.push(a);
        }
      });
    } else {
      this.ticketResult.forEach(a => {
        if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
          a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {
          this.finalTickets.push(a);
        }
      });
    }
  }

  getTicketsByDue(due: string) {
    this.dueTickets = due;
    this.getTickets(this.checkStatus, this.dueTickets);
  }
  
  
 getTickets(status: string, due: string) {
    this.auth = localStorage.getItem("Auth");
    if (this.dueTickets !== null && this.dueTickets !== undefined && this.dueTickets !== "") {
      due = this.dueTickets;
    }
    var fourDaysPastCurrentDate = new Date();
    fourDaysPastCurrentDate.setDate(fourDaysPastCurrentDate.getDate() + 4);

    if (status === 'Open') {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
    this.checkStatus = status;
    this.finalTickets = [];
    const headers = {
      "Authorization": 'Basic ' + this.auth,
      "Access-Control-Allow-Origin": "*"
    };
    let params = '';
    if (this.checkStatus !== 'All') {
      if (this.adminView !== 'yes') {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Onboarding AND ("Delegate Manager" = ' + '"' + this.user + '"' + ' OR Manager =' + '"' +  this.user + '")' + ' AND status = ' + '"' + this.checkStatus + '"' + ' ORDER BY  duedate &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,,customfield_26322,customfield_65604,customfield_66300,duedate,created';
      } else {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Onboarding AND status = ' + '"' + this.checkStatus + '"' + ' ORDER BY  duedate  &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,duedate,created ';
      }
    } else {
      if (this.adminView !== 'yes') {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Onboarding AND ("Delegate Manager" = ' + '"' +  this.user + '"' + ' OR Manager =' + '"' + this.user + '")' + ' ORDER BY  duedate &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,,customfield_26322,customfield_65604,customfield_66300,duedate,created';
      } else {
        params = 'search?jql=project = "Human Resources Service Desk"  AND type  = Onboarding ORDER BY  duedate  &fields=status, customfield_26140,customfield_26136,customfield_26119,customfield_10659, customfield_10660,customfield_10668,customfield_18700,customfield_26328,customfield_26316,customfield_16302,customfield_26130,customfield_26136,customfield_15400,customfield_28600,customfield_26322,customfield_65604,customfield_66300,duedate,created';
      }
    }
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.ticketCount = data.total;
          this.ticketResult = data.issues;
          localStorage.setItem("AllTickets", JSON.stringify(this.ticketResult));
          this.ticketResult.forEach(a => {
            if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
            a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {
             console.log( a.fields?.customfield_16302);
             let dept : String =  a.fields?.customfield_16302.toString();
              if (dept.toLowerCase().includes('engineering')) {
                a.fields.isEng = "yes";
              } else {
                a.fields.isEng = "no";
              }  
              console.log('Engineering ', a.fields.isEng);
              a.fields.alert = "white";
              if (a.fields.status.name !== 'Closed' && a.fields.status.name !== 'Withdrawn') {
                if (a.fields.duedate !== null && a.fields.duedate !== undefined && a.fields.duedate !== '' && new Date(a.fields.duedate) < new Date()) {
                  a.fields.alert = "red";
                } else if (a.fields.duedate !== null && a.fields.duedate !== undefined && a.fields.duedate !== '' && new Date(a.fields.duedate) <= fourDaysPastCurrentDate) {
                  a.fields.alert = "yellow";
                } else {
                  a.fields.alert = "green";
                }
              }
              if (this.checkStatus !== 'Closed' && this.checkStatus !== 'Withdrawn') {
                if (due === 'Overdue') {
                  if (a.fields.alert === "red") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duewithin5') {
                  if (a.fields.alert === "yellow") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duemorethan5') {
                  if (a.fields.alert === "green") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'All') {
                  this.finalTickets.push(a);
                }
              } else {
                this.finalTickets.push(a);
              }
            }
          });
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
    }
  
  /*
  getTickets(status: string, due: string) { 
    if (this.dueTickets !== null && this.dueTickets !== undefined && this.dueTickets !== "") {
      due = this.dueTickets;
    }
    var fourDaysPastCurrentDate = new Date();
    fourDaysPastCurrentDate.setDate(fourDaysPastCurrentDate.getDate() + 4);

    if (status === 'Open') {
      this.isOpen = true;
    } else {
      this.isOpen = false;
    }
    this.checkStatus = status;
    this.finalTickets = [];
    const params = new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('status', this.checkStatus)
      .set('admin', this.adminView);
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };
    this.http.get<any>(environment.api + "onboarding", { params, headers })
      .subscribe({
        next: data => {
          this.ticketCount = data.total;
          this.ticketResult = data.issues;
          localStorage.setItem("AllTickets", JSON.stringify(this.ticketResult));
          this.ticketResult.forEach(a => {
            if ((this.checkStatus === a.fields.status.name || this.checkStatus === 'All') &&
              a.fields.customfield_10659 !== null && a.fields.customfield_10659 !== undefined && a.fields.customfield_10659 !== "") {
               console.log( a.fields?.customfield_16302);
               let dept : String =  a.fields?.customfield_16302.toString();
                if (dept.toLowerCase().includes('engineering') && this.vmsViewAccess) {
                  a.fields.isEng = "yes";
                } else {
                  a.fields.isEng = "no";
                }  
              console.log('Engineering ', a.fields.isEng);
              a.fields.alert = "white";
              if (a.fields.status.name !== 'Closed' && a.fields.status.name !== 'Withdrawn') {
                if (a.fields.duedate !== null && a.fields.duedate !== undefined && a.fields.duedate !== '' && new Date(a.fields.duedate) < new Date()) {
                  a.fields.alert = "red";
                } else if (a.fields.duedate !== null && a.fields.duedate !== undefined && a.fields.duedate !== '' && new Date(a.fields.duedate) <= fourDaysPastCurrentDate) {
                  a.fields.alert = "yellow";
                } else {
                  a.fields.alert = "green";
                }
              }
              if (this.checkStatus !== 'Closed' && this.checkStatus !== 'Withdrawn') {
                if (due === 'Overdue') {
                  if (a.fields.alert === "red") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duewithin5') {
                  if (a.fields.alert === "yellow") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'Duemorethan5') {
                  if (a.fields.alert === "green") {
                    this.finalTickets.push(a);
                  }
                } else if (due === 'All') {
                  this.finalTickets.push(a);
                }
              } else {
                this.finalTickets.push(a);
              }
            }
          }); 
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }  */

} 


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}