import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'

interface SearchResults {
  total: number;
  results: Array<object>;
}

@Component({
  selector: 'vendor-onboarding-page.component',
  templateUrl: './vendor-onboarding-page.component.html',
  styleUrls: ['./vendor-onboarding-page.component.css']
})
export class vendoronboardingPageComponent implements OnInit {


  vendoronboardingForm: FormGroup;
  salesflag: boolean = false;
  editorialflag: boolean = false;
  additionalflag: boolean = false;
  ticket: any;
  employeeName: any;
  employeeId: any;
  data: any[] = [];
  users: any[] = [];
  errorMessage: any;
  hrEmployeeType: any[] = [];
  hireType: any[] = [];
  phoneDetails: any[] = [];
  workSpaceLocation: any[] = [];
  workSpaceImprovements: any[] = [];
  computingEquipments: any[] = [];
  applicationGroup: any[] = [];
  amexCardRequired: any[] = [];
  newSalesForceAccount: any[] = [];
  salesForceUserType: any[] = [];
  editorialPersona: any[] = [];
  bwriteRole: any[] = [];
  deliverEquipment: any[] = [];
  user: any;
  password: any;
  showBWrite: boolean = false;
  auth: any;
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.vendoronboardingForm = this.formBuilder.group({
      firstname: [null, Validators.required],
      lastname: [null, Validators.required],
      dateofbirth: [null, Validators.required],
      email: [null, Validators.required],
      cell: [null, Validators.required],
      address: [null, Validators.required],
      jtitle: [null, Validators.required],
      vcompany: [null, Validators.required],
      vsupervisor: [null, Validators.required],
      manager: [null, Validators.required],
      startdate: [null, Validators.required],
      enddate: [null, Validators.required],

    });
  }

  ngOnInit() {
    this.submitted = false;
    this.showBWrite = false;
    this.vendoronboardingForm.controls['manager'].setValidators([Validators.required, Validators.pattern('.*[0-9].*')]); 
    localStorage.setItem("audit", '');
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("edit/");
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          this.employeeName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.employeeId = a.fields.customfield_26328;
        }
      });
    }
  }


  onSubmit(): void {
    console.warn('Form Values', this.vendoronboardingForm.value);
    console.log(this.vendoronboardingForm.valid);
    if (this.vendoronboardingForm.valid) {
      this.submitted = true;
      this.pushDetailsToJIRA(this.vendoronboardingForm.value, 'submit');
    }
  }


  saveDetails(): void {
    this.pushDetailsToJIRA(this.vendoronboardingForm.value, 'save');
  }

  saveandFinishDetails(): void {
    this.pushDetailsToJIRA(this.vendoronboardingForm.value, 'saveAndFinish');
    this.router.navigate(['../hire'], { relativeTo: this.route });
  }

  
  getUsers(input: string) {
      this.auth = localStorage.getItem("Auth");
       this.users = [];
       const headers = {
         'Authorization': 'Basic ' + this.auth,
         'Access-Control-Allow-Origin': '*'
       };
       const params = 'groupuserpicker?query=' + input;
       this.http.get<any>(environment.jiraAPI + params, { headers })
         .subscribe({
           next: data => {
             this.users = data?.users?.users;
             console.log('User is ', this.users);
           },
           error: error => {
             this.errorMessage = error.message;
             console.error('Error ', error);
           }
         })
   
     } 
  


  pushDetailsToJIRA(formValues: any, action : any) {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue'
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formPayload(formValues, action);   
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
             if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {   
            if (action === 'submit')  { 
               alert("Success ! \n\nYour Entry has been submitted!");   
               this.router.navigate(['../hire'], { relativeTo: this.route });
            } else if ((action === 'submit')) {     
                alert("Success ! \n\nYour Entry has been saved!");     
                this.router.navigate(['../hire'], { relativeTo: this.route });
             } else {
                alert("Success ! \n\nYour Entry has been saved!"); 
             }
         }  else {
           alert("An Error occured while submitting vendor details to Jira.");             
         }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }  

  

  formPayload(formValue: any, action: any) {
    console.log('Payload ', formValue);
    let fields: any = {};


    let project = { id: "14900" };
    fields.project = project;

    let issuetype = { id: "15500" };
    fields.issuetype = issuetype;

    let reporter = { name: this.user };
    fields.reporter = reporter;

    let priority = { id: "4" };
    fields.priority = priority;


    let security = { id: "11100" };
    fields.security = security;


    let summary = "New Hire Information for " + formValue.firstname + " " + formValue.lastname + " (Vendor)";
    fields.summary = summary;
    fields.description = summary;

    let customfield_26136 = { value: "Vendor" };
    fields.customfield_26136 = customfield_26136;

    let customfield_18700 = { value: "Non-Bargaining" };
    fields.customfield_18700 = customfield_18700;

    let customfield_31903 = { value: "Vendor" };
    fields.customfield_31903 = customfield_31903;

    let customfield_26140 = "Bloomberg Industry Group, Inc.";
    fields.customfield_26140 = customfield_26140;

    let customfield_16302 = "Engineering";
    fields.customfield_16302 = customfield_16302;

    if (formValue.firstname !== null && formValue.firstname !== undefined && formValue.firstname != "") {
      let customfield_10659 = formValue.firstname;
      fields.customfield_10659 = customfield_10659;
    }
    if (formValue.lastname !== null && formValue.lastname !== undefined && formValue.lastname != "") {
      let customfield_10660 = formValue.lastname;
      fields.customfield_10660 = customfield_10660;
    }
    if (formValue.email !== null && formValue.email !== undefined && formValue.email != "") {
      let customfield_67200 = formValue.email;
      fields.customfield_67200 = customfield_67200;
    }
    if (formValue.jtitle !== null && formValue.jtitle !== undefined && formValue.jtitle != "") {
      let customfield_71703 = formValue.jtitle;
      fields.customfield_71703 = customfield_71703;
    }
    if (formValue.vcompany !== null && formValue.vcompany !== undefined && formValue.vcompany != "") {
      let customfield_71701 = formValue.vcompany;
      fields.customfield_71701 = customfield_71701;
    }
    if (formValue.vsupervisor !== null && formValue.vsupervisor !== undefined && formValue.vsupervisor != "") {
      let customfield_71702 = formValue.vsupervisor;
      fields.customfield_71702 = customfield_71702;
    }
    if (formValue.manager !== null && formValue.manager !== undefined && formValue.manager != "") {
      let customfield_15400 = { name: formValue.manager };
      fields.customfield_15400 = customfield_15400;
    }
    if (formValue.startdate !== null && formValue.startdate !== undefined && formValue.startdate != "") {
      let customfield_26316 = formValue.startdate;
      fields.customfield_26316 = customfield_26316;
    }
    if (formValue.enddate !== null && formValue.enddate !== undefined && formValue.enddate != "") {
      let customfield_26315 = formValue.enddate;
      fields.customfield_26315 = customfield_26315;
    }
    if (formValue.dateofbirth !== null && formValue.dateofbirth !== undefined && formValue.dateofbirth != "") {
      let customfield_72300 = formValue.dateofbirth;
      fields.customfield_72300 = customfield_72300;
    }
    if (formValue.cell !== null && formValue.cell !== undefined && formValue.cell != "") {
      let customfield_11500 = formValue.cell;
      fields.customfield_11500 = customfield_11500;
    }
    if (formValue.address !== null && formValue.address !== undefined && formValue.address != "") {
      let customfield_10662 = formValue.address;
      fields.customfield_10662 = customfield_10662;
    }

    if (action === 'submit') {
      let customfield_71801 = { value: "Yes" };;
      fields.customfield_71801 = customfield_71801;
    }


    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let customfield_66000: string = "On " + systemDate + " , " + loginUser + " has added following details to the ticket : " + JSON.stringify(formValue);
    fields.customfield_66000 = customfield_66000;
    localStorage.setItem("audit", customfield_66000);
    console.log('Fields ', fields);
    return fields;
  }




/*
  getUsers(input: string) {
    this.users = [];
    const params = new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('input', input)
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };
    this.http.get<any>(environment.api + "users", { params, headers })
      .subscribe({
        next: data => {
          this.users = data?.users?.users;
          console.log('User is ', this.users);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }



  pushDetailsToJIRA(formValues: any, action: any) {
    const params = new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('ticket', this.ticket)
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    let fields = this.formPayload(formValues, action);
    this.http.put<any>(environment.api + "onboarding/create", JSON.stringify({ fields }), { params, headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            if (action === 'submit') {
              alert("Success ! \n\nYour Entry has been submitted!");
              this.router.navigate(['../hire'], { relativeTo: this.route });
            } else if ((action === 'submit')) {
              alert("Success ! \n\nYour Entry has been saved!");
              this.router.navigate(['../hire'], { relativeTo: this.route });
            } else {
              alert("Success ! \n\nYour Entry has been saved!");
            }
          } else {
            alert("An Error occured while submitting vendor details to Jira");
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }
*/

}