import { Component, OnInit } from '@angular/core';
import { OktaService } from '../authenticate/okta.service';
import { environment } from '../../environments/environment'
import { HttpClient, HttpParams } from '@angular/common/http';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'landing-page.component',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {


  title = 'EJourney';
  adminViewAccess: boolean = false;
  mySwitch: boolean = false;
  userName: string = "";
  errorMessage: any;
  unAuthorizedUser: boolean = false;
  auth : any  ;
  jiraError : any = 'yes';
  //jiraError : any = 'no';
  constructor(private oktaService: OktaService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) {
      
  }


  ngOnInit() {
  this.auth = "";
    this.adminViewAccess = false;
    let claims: any = this.oktaService.getClaims();
    let user = '';
    if (claims) {
      console.log('Claims', claims);
      user = claims['preferred_username'];
      let authToken = claims['name'];
     console.log("Auth token ", authToken);  
     localStorage.setItem("Auth", authToken); 
    }
    if (user !== '') {
      let str = user.split("@");
      if (str.length > 0) {
        this.userName = str[0];
      }
    }
    console.log('Username', this.userName);
    this.validateLoginUserName(this.userName); 
    console.log('Admin Mode Access', this.adminViewAccess);   
    if (localStorage.getItem("adminMode") === 'yes') {
      this.mySwitch = true;
    }
  }

  adminSwitch() {
    if (this.mySwitch === true) {
      localStorage.setItem("adminMode", "yes");
    } else {
      localStorage.setItem("adminMode", "no");
    }
    console.log('Admin View', localStorage.getItem("adminMode"));
  }


  validateLoginUserName(user: string) {
    this.auth = localStorage.getItem("Auth");
    console.log("Storage Auth ", this.auth);
    localStorage.setItem("loginUser", user); 
    localStorage.setItem("isAdmin", "no");  
    const headers = {
      "Authorization": 'Basic ' + this.auth,
      "Access-Control-Allow-Origin": "*"
    };
    const params = 'user?username=' + user + '&expand=groups';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.jiraError = 'no';
          console.log('data ', data);
          this.unAuthorizedUser = false;
          let groupList: any[] = [];
          let adminGroup: any;
          let vmsGroup: any;
          groupList = data?.groups?.items;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            adminGroup = groupList.find(a =>
              a.name?.toLowerCase() === 'pegasus' || a.name?.toLowerCase() === 'grp_hr' || a.name?.toLowerCase() === 'hr-all' || a.name?.toLowerCase() === 'it_tier1')
            vmsGroup = groupList.find(a =>
                a.name?.toLowerCase() === 'vendor management specialist' ||  a.name?.toLowerCase() === 'grp_indg_engineering_mgrs_people_leaders')
           
            };
          if (adminGroup !== null && adminGroup !== undefined && adminGroup !== '') {
            localStorage.setItem("isAdmin", "yes");
            this.adminViewAccess = true;
          }
          if (vmsGroup !== null && vmsGroup !== undefined && vmsGroup !== '') {
            localStorage.setItem("isvms", "yes");              
          }
          let tier1Group: any;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            tier1Group = groupList.find(a =>  a.name?.toLowerCase() === 'pegasus' || a.name?.toLowerCase() === 'grp_hr' || a.name?.toLowerCase() === 'hr-all' || a.name?.toLowerCase() === 'it_tier1')
          };
          if (tier1Group !== null && tier1Group !== undefined && tier1Group !== '') {
            localStorage.setItem("tier1", "yes");      
          }
          let managerGroup: any;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            managerGroup = groupList.find(a =>    a.name?.toLowerCase() === 'pegasus' || a.name?.toLowerCase() === 'grp_hr' || a.name?.toLowerCase() === 'hr-all' || a.name?.toLowerCase() === 'it_tier1' ||  a.name?.toLowerCase() === 'grp_indg_all_managers')
          };
          if (managerGroup !== null && managerGroup !== undefined && managerGroup !== '') {
            localStorage.setItem("manager_group", "yes");      
          }
          console.log('Logged in Successfully ', data.displayName);
          localStorage.setItem("username", data.displayName); 
          console.log('Admin ', localStorage.getItem("isAdmin"));     
        },
        error: error => {
          console.log('Error ', error);
          this.jiraError = 'yes';
          this.errorMessage = error.message;
          if (error.status === 401) {
            this.unAuthorizedUser = true;
          } 
        }
      })
  } 
  
/*
  validateLoginUserName(user: string) {
    console.log("Storage Auth ", localStorage.getItem("Auth"));
    let username = 'sb118362';
    let password = 'Godplease#1290';
    localStorage.setItem("loginUser", username);
    localStorage.setItem("loginPassword", password);
    localStorage.setItem("isAdmin", "no");
    const params = new HttpParams()
      .set('username', username)
      .set('password', password);
    const headers = {
      'Access-Control-Allow-Origin': '*',   
    "Access-Control-Allow-Methods" : "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers" : "access-control-allow-origin",
    "Access-Control-Allow-Credentials": "true"
    };
    this.http.get<any>(environment.api + "login", { params, headers })
      .subscribe({
        next: data => {
          this.jiraError = 'no';
          this.unAuthorizedUser = false;
          let groupList: any[] = [];
          let adminGroup: any;
          let vmsGroup: any;
          groupList = data?.groups?.items;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            adminGroup = groupList.find(a =>
              a.name?.toLowerCase() === 'pegasus' || a.name?.toLowerCase() === 'grp_hr' || a.name?.toLowerCase() === 'hr-all' || a.name?.toLowerCase() === 'it_tier1')
            vmsGroup = groupList.find(a =>
                a.name?.toLowerCase() === 'vendor management specialist')
           
          };
          if (adminGroup !== null && adminGroup !== undefined && adminGroup !== '') {
            localStorage.setItem("isAdmin", "yes");
            this.adminViewAccess = true;
          }
          if (vmsGroup !== null && vmsGroup !== undefined && vmsGroup !== '') {
            localStorage.setItem("isvms", "yes");             
          }
          let tier1Group: any;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            tier1Group = groupList.find(a =>   a.name?.toLowerCase() === 'pegasus' ||  a.name?.toLowerCase() === 'grp_hr' || a.name?.toLowerCase() === 'hr-all' || a.name?.toLowerCase() === 'it_tier1')
          };
          if (tier1Group !== null && tier1Group !== undefined && tier1Group !== '') {
            localStorage.setItem("tier1", "yes");      
          }
          let managerGroup: any;
          if (groupList !== null && groupList !== undefined && groupList.length > 0) {
            managerGroup = groupList.find(a =>   a.name?.toLowerCase() === 'pegasus' || a.name?.toLowerCase() === 'it_tier1' ||  a.name?.toLowerCase() === 'Grp_INDG_All_Managers')
          };
          if (managerGroup !== null && managerGroup !== undefined && managerGroup !== '') {
            localStorage.setItem("manager_group", "yes");      
          }
          console.log('Logged in Successfully ', data.displayName);
          localStorage.setItem("username", data.displayName); 
          console.log('Admin ', localStorage.getItem("isAdmin"));
          this.router.navigate(['/ejourney'], { relativeTo: this.route });
        },
        error: error => {
          console.log(error);
          this.jiraError = 'yes';
          if (error.error?.text?.includes('401')) {
            this.unAuthorizedUser = true;
          }
        }
      })
  } 
*/
}
