import { Component, OnInit } from '@angular/core';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  template: '<div><center><strong><p style="background-color: #fed3d3;   color: #820000;  padding: 1rem;      margin-bottom: 1rem; " role="alert">Please use Google Chrome browser to fill EJourney form. If you are receiving an error on google chrome browser, then you dont have access to EJourney (grp_okta_EJourney_Prod AD group). please submit a <a href="https://jira.bna.com/servicedesk/customer/portal/302/create/3150"  target="_blank">Service Desk Ticket</a></p></strong></center></div>'
})
export class LoginComponent implements OnInit {

  constructor( private router: Router,  private route: ActivatedRoute) {
   
  }
  
  public ngOnInit() {
    
  }
  




}
