import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'landing-page.component',
  templateUrl: './transfer-dashboard-edit.component.html',
  styleUrls: ['./transfer-dashboard-edit.component.css']
})
export class TransferDashboardEditComponent implements OnInit {

  transferForm: FormGroup;
  currenttransferForm: FormGroup;
  salesflag: boolean = false;
  editorialflag: boolean = false;
  additionalflag: boolean = false;
  ticket: any;
  employeeName: any;
  employeeId: any;
  data: any[] = [];
  users: any[] = [];
  errorMessage: any;
  hrEmployeeType: any[] = [];
  hireType: any[] = [];
  phoneDetails: any[] = [];
  workSpaceLocation: any[] = [];
  workSpaceImprovements: any[] = [];
  computingEquipments: any[] = [];
  amexCardRequired: any[] = [];
  applicationGroup: any[] = [];
  newSalesForceAccount: any[] = [];
  salesForceUserType: any[] = [];
  editorialPersona: any[] = [];
  bwriteRole: any[] = [];
  deliverEquipment: any[] = [];
  removeAMEX: any[] = [];
  removeAccess: any[] = [];
  currentManager: boolean = false;
  newManager: boolean = false;
  previousManagerCompleted: boolean = false;
  newManagerCompleted: boolean = false;
  user: any;
  password: any;
  anyChangesRequired: boolean = false;
  showBWrite: boolean = false;
  auth: any;
  audit: String = '';
  submitted: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient) {
    this.transferForm = this.formBuilder.group({
      buddy: [null, Validators.required],
      modelaccess: [null, Validators.required],
      etype: ["", Validators.required],
      htype: [""],
      difffirstname: [null],
      managerphoneno: [null],
      existingphoneno: [null],
      phone: ["", Validators.required],
      location: ["", Validators.required],
      workspaceno: [null, Validators.required],
      workspaceimprovements: [""],
      computingequipments: [""],
      workspacecomments: [null],
      amexcardrequired: [""],
      applicationgroup: ["", Validators.required],
      salesforceaccount: [""],
      salesforceuser: [""],
      salesforceuserafter: [null],
      editorialperson: [""],
      bwrite: [""],
      bwritepublication: [null],
      additionaleditorial: [null],
      additionalapps: [null],
      additionalapplication: [null]
    });
    this.currenttransferForm = this.formBuilder.group({
      anyChangesRequired: ["", Validators.required],
      removeadditionalapplication: [null],
      removeTerminalAccess: [""],
      removeAMEXAccess: [""]
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.showBWrite = false;
    this.anyChangesRequired = false;
    this.user = localStorage.getItem("loginUser");
    this.password = localStorage.getItem("loginPassword");
    this.auth = localStorage.getItem("Auth");
    this.data = JSON.parse(localStorage.getItem("AllTickets") || '{}');
    let currentURL = window.location.href;
    let str = currentURL.split("edit/");
    console.log('String url ', str);
    if (str[0].includes('current')) {
      this.currentManager = true;
      this.newManager = false;
    } else if (str[0].includes('new')) {
      this.currentManager = false;
      this.newManager = true;
    } else {
      this.currentManager = false;
      this.newManager = false;
    }
    console.log('Current Manager ', this.currentManager);
    console.log('New Manager ', this.newManager);
    this.ticket = str[1]
    if (this.data !== null && this.data !== undefined && this.data.length > 0) {
      this.data.forEach(a => {
        if (this.ticket === a.key) {
          this.employeeName = a.fields.customfield_10659 + ' ' + a.fields.customfield_10660;
          this.employeeId = a.fields.customfield_26328;
        }
      });
    }
    this.getDropdownValues();
    this.getTicketAudit();
  }

  getApplicationGroup(event: any) {
    let appGroup = event.target.value;
    console.log('Application Group is ', appGroup);
    if (appGroup !== "" && appGroup !== null && appGroup !== undefined) {
      if (appGroup === "Customer Operations" || appGroup === "Sales") {
        this.salesflag = true;
        this.editorialflag = false;
        this.additionalflag = true;
        this.transferForm.controls['salesforceaccount'].setValidators(Validators.required);
        this.transferForm.controls['editorialperson'].clearValidators();
        this.transferForm.controls['bwrite'].clearValidators();
        this.transferForm.controls['bwritepublication'].clearValidators();
        this.transferForm.controls['additionaleditorial'].clearValidators();
        this.transferForm.controls['editorialperson'].updateValueAndValidity();
        this.transferForm.controls['bwrite'].updateValueAndValidity();
        this.transferForm.controls['bwritepublication'].updateValueAndValidity();
        this.transferForm.controls['additionaleditorial'].updateValueAndValidity();

      } else if (appGroup === "Editorial") {
        this.editorialflag = true;
        this.salesflag = false;
        this.additionalflag = true;
        this.transferForm.controls['editorialperson'].setValidators(Validators.required);
        this.transferForm.controls['salesforceaccount'].clearValidators();
        this.transferForm.controls['salesforceuser'].clearValidators();
        this.transferForm.controls['salesforceuserafter'].clearValidators();
        this.transferForm.controls['salesforceaccount'].updateValueAndValidity();
        this.transferForm.controls['salesforceuser'].updateValueAndValidity();
        this.transferForm.controls['salesforceuserafter'].updateValueAndValidity();


      } else if (appGroup === "Editorial/Sales") {
        this.salesflag = true;
        this.editorialflag = true;
        this.additionalflag = true;
        this.transferForm.controls['salesforceaccount'].setValidators(Validators.required);
        this.transferForm.controls['editorialperson'].setValidators(Validators.required);
      } else {
        this.salesflag = false;
        this.editorialflag = false;
        this.additionalflag = true;
        this.transferForm.controls['salesforceaccount'].clearValidators();
        this.transferForm.controls['salesforceuser'].clearValidators();
        this.transferForm.controls['salesforceuserafter'].clearValidators();
        this.transferForm.controls['editorialperson'].clearValidators();
        this.transferForm.controls['bwrite'].clearValidators();
        this.transferForm.controls['bwritepublication'].clearValidators();
        this.transferForm.controls['additionaleditorial'].clearValidators();
        this.transferForm.controls['salesforceaccount'].updateValueAndValidity();
        this.transferForm.controls['salesforceuser'].updateValueAndValidity();
        this.transferForm.controls['salesforceuserafter'].updateValueAndValidity();
        this.transferForm.controls['editorialperson'].updateValueAndValidity();
        this.transferForm.controls['bwrite'].updateValueAndValidity();
        this.transferForm.controls['bwritepublication'].updateValueAndValidity();
        this.transferForm.controls['additionaleditorial'].updateValueAndValidity();

      }
    } else {
      this.salesflag = false;
      this.editorialflag = false;
      this.additionalflag = false;
      this.transferForm.controls['salesforceaccount'].clearValidators();
      this.transferForm.controls['salesforceuser'].clearValidators();
      this.transferForm.controls['salesforceuserafter'].clearValidators();
      this.transferForm.controls['editorialperson'].clearValidators();
      this.transferForm.controls['bwrite'].clearValidators();
      this.transferForm.controls['bwritepublication'].clearValidators();
      this.transferForm.controls['additionaleditorial'].clearValidators();
      this.transferForm.controls['additionalapps'].clearValidators();
      this.transferForm.controls['additionalapplication'].clearValidators();
      this.transferForm.controls['salesforceaccount'].updateValueAndValidity();
      this.transferForm.controls['salesforceuser'].updateValueAndValidity();
      this.transferForm.controls['salesforceuserafter'].updateValueAndValidity();
      this.transferForm.controls['editorialperson'].updateValueAndValidity();
      this.transferForm.controls['bwrite'].updateValueAndValidity();
      this.transferForm.controls['bwritepublication'].updateValueAndValidity();
      this.transferForm.controls['additionaleditorial'].updateValueAndValidity();
      this.transferForm.controls['additionalapps'].updateValueAndValidity();
      this.transferForm.controls['additionalapplication'].updateValueAndValidity();
    }
  }

  getEditorial(event: any) {
    this.showBWrite = false;
    let editorial = event.target.value;
    if (editorial !== null && editorial !== undefined && editorial !== "") {
      if (editorial.toLowerCase().includes('editorial') || editorial.toLowerCase().includes('news')) {
        this.showBWrite = true;
        this.transferForm.controls['bwrite'].setValidators(Validators.required);
        this.transferForm.controls['bwritepublication'].setValidators(Validators.required);
      } else {
        this.showBWrite = false;
        this.transferForm.controls['bwrite'].clearValidators();
        this.transferForm.controls['bwrite'].updateValueAndValidity();
        this.transferForm.controls['bwritepublication'].clearValidators();
        this.transferForm.controls['bwritepublication'].updateValueAndValidity();
      }
    }
  }

  getRemoveAccess(event: any) {
    console.log('Event ', event);
    this.anyChangesRequired = false;
    this.currenttransferForm.controls['removeTerminalAccess'].clearValidators();
    this.currenttransferForm.controls['removeAMEXAccess'].clearValidators();
    this.currenttransferForm.controls['removeadditionalapplication'].clearValidators();
    this.currenttransferForm.controls['removeTerminalAccess'].updateValueAndValidity();
    this.currenttransferForm.controls['removeAMEXAccess'].updateValueAndValidity();
    this.currenttransferForm.controls['removeadditionalapplication'].updateValueAndValidity();
    if (event !== "" && event !== null && event !== undefined && event.target?.value == 'Yes') {
      this.anyChangesRequired = true;
    }
  }



  onPreviousSubmit(): void {
    console.warn('Form Values', this.currenttransferForm.value);
    if (this.currenttransferForm.valid) {
      this.pushPreviousManagerDetailsToJIRA(this.currenttransferForm.value);
    }
  }


  onSubmit(): void {
    console.warn('Form Values', this.transferForm.value);
    if (this.transferForm.valid) {
      this.submitted = true;
      this.pushDetailsToJIRA(this.transferForm.value);
    }
  }


  getUsers(input: string) {
    this.auth = localStorage.getItem("Auth");
    this.users = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'groupuserpicker?query=' + input;
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.users = data?.users?.users;
          console.log('User is ', this.users);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }

  getDropdownValues() {
    this.auth = localStorage.getItem("Auth");
    this.workSpaceLocation = [];
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    const params = 'issue/createmeta/HRSD/issuetypes/27500?maxResults=100';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {


          const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // HR Employee Type           
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_31903" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const hrEmployeeTypeList: any[] = element.allowedValues;
                hrEmployeeTypeList.forEach(item => {
                  this.hrEmployeeType.push(item.value);
                })
              }
            });


            // Hire Type         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_35300" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const hireTypeList: any[] = element.allowedValues;
                  hireTypeList.forEach(item => {
                    this.hireType.push(item.value);
                  })
                }
              });
            }

            // Phone Details        
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26129" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const phoneDetailsList: any[] = element.allowedValues;
                  phoneDetailsList.forEach(item => {
                    this.phoneDetails.push(item.value);
                  })
                }
              });
            }


            // Workspace Location      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceLocationList: any[] = element.allowedValues;
                  workSpaceLocationList.forEach(item => {
                    this.workSpaceLocation.push(item.value);
                  })
                }
              });
            }


            // Workspace Improvements   
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26331" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceImprovementsList: any[] = element.allowedValues;
                  workSpaceImprovementsList.forEach(item => {
                    this.workSpaceImprovements.push(item.value);
                  })
                }
              });
            }

            // Computing Equipments         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26308" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const computingEquipmentsList: any[] = element.allowedValues;
                  computingEquipmentsList.forEach(item => {
                    this.computingEquipments.push(item.value);
                  })
                }
              });
            }

            // AMEX Card Required      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_59600" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const amexCardRequiredList: any[] = element.allowedValues;
                  amexCardRequiredList.forEach(item => {
                    this.amexCardRequired.push(item.value);
                  })
                }
              });
            }


            // Application Group   
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26304" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const applicationGroupList: any[] = element.allowedValues;
                  applicationGroupList.forEach(item => {
                    this.applicationGroup.push(item.value);
                  })
                }
              });
            }


            // New Sales Force Account
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26325" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const newSalesForceAccountList: any[] = element.allowedValues;
                  newSalesForceAccountList.forEach(item => {
                    this.newSalesForceAccount.push(item.value);
                  })
                }
              });
            }

            // salesForceUserType
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26326" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const salesForceUserTypeList: any[] = element.allowedValues;
                  salesForceUserTypeList.forEach(item => {
                    this.salesForceUserType.push(item.value);
                  })
                }
              });
            }


            // Editorial Persona
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26310" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const editorialPersonaList: any[] = element.allowedValues;
                  editorialPersonaList.forEach(item => {
                    this.editorialPersona.push(item.value);
                  })
                }
              });
            }

            // BWrite Role
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26306" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const bwriteRoleList: any[] = element.allowedValues;
                  bwriteRoleList.forEach(item => {
                    this.bwriteRole.push(item.value);
                  })
                }
              });
            }

            // Deliver Equipment To
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26119" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const deliverEquipmentList: any[] = element.allowedValues;
                  deliverEquipmentList.forEach(item => {
                    this.deliverEquipment.push(item.value);
                  })
                }
              });
            }


            // Remove AMEX Card
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_65602" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const removeAMEXList: any[] = element.allowedValues;
                  removeAMEXList.forEach(item => {
                    this.removeAMEX.push(item.value);
                  })
                }
              });
            }

            // Remove Access (Terminal)
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_21700" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const removeAccessList: any[] = element.allowedValues;
                  removeAccessList.forEach(item => {
                    this.removeAccess.push(item.value);
                  })
                }
              });
            }




          }


          console.log('HR Employee Type ', this.hrEmployeeType);
          console.log('Phone Details ', this.phoneDetails);
          console.log('WorkSpace Location ', this.workSpaceLocation);
          console.log('Application Group ', this.applicationGroup);
          console.log('New Sales Force Account ', this.newSalesForceAccount);
          console.log('Sales Force User Type ', this.salesForceUserType);
          console.log('Editorial Persona ', this.editorialPersona);
          console.log('BWrite Role ', this.bwriteRole);
          console.log('Deliver Equipment To ', this.deliverEquipment);
          console.log('Remove AMEX ', this.removeAMEX);
          console.log('Remove Access ', this.removeAccess);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }

  getTicketAudit() {
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Access-Control-Allow-Origin': '*'
    };
    let params = 'issue/' + this.ticket + '?fields=customfield_66000';
    this.http.get<any>(environment.jiraAPI + params, { headers })
      .subscribe({
        next: data => {
          this.audit = data.fields?.customfield_66000;
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }

  pushPreviousManagerDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    this.previousManagerCompleted = false;
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    let fields = this.formPayloadForPreviousManager(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          this.previousManagerCompleted = true;
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            this.updateStatus();
            setTimeout(() => {
              this.pushAuditDetailsToJIRA();
              alert("Previous Manager Details Submited Successfully");
              this.router.navigate(['../../transfer'], { relativeTo: this.route });
            }, 2000);
          } else {
            alert("An Error occured while submitting employee details to Jira, please find the error " + JSON.stringify(data?.errors));
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
          this.previousManagerCompleted = false;
        }
      })

  }



  pushDetailsToJIRA(formValues: any) {
    this.auth = localStorage.getItem("Auth");
    this.newManagerCompleted = false;
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    let fields = this.formPayload(formValues);
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
          this.newManagerCompleted = true;
          if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined || data?.errors == '') {
            this.updateStatus();
            setTimeout(() => {
              this.pushAuditDetailsToJIRA();
              alert("New Manager Details Submited Successfully");
              this.router.navigate(['../../transfer'], { relativeTo: this.route });
            }, 2000);
          } else {
            alert("An Error occured while submitting employee details to Jira, please find the error " + JSON.stringify(data?.errors));
          }
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
          this.newManagerCompleted = false;
        }
      })

  }


  updateStatus() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket + '/transitions?expand=transitions.fields';
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    let transition = {
      "id": "41"
    };
    this.http.post<any>(environment.jiraAPI + params, JSON.stringify({ transition }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
  }



  pushAuditDetailsToJIRA() {
    this.auth = localStorage.getItem("Auth");
    const params = 'issue/' + this.ticket;
    const headers = {
      'Authorization': 'Basic ' + this.auth,
      'Content-Type': 'application/json',
      'X-Atlassian-Token': 'no-check'
    };
    let fields = this.formAuditPayload();
    this.http.put<any>(environment.jiraAPI + params, JSON.stringify({ fields }), { headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })

  }




  formPayloadForPreviousManager(formValue: any) {
    let fields: any = {};

    if (formValue.anyChangesRequired !== null && formValue.anyChangesRequired !== undefined && formValue.anyChangesRequired != "") {
      let customfield_65604 = { value: formValue.anyChangesRequired };
      fields.customfield_65604 = customfield_65604;
    }
    if (fields.customfield_65604.value === 'Yes') {
      if (formValue.removeadditionalapplication !== null && formValue.removeadditionalapplication !== undefined && formValue.removeadditionalapplication != "") {
        let customfield_65603 = formValue.removeadditionalapplication;
        fields.customfield_65603 = customfield_65603;
      }
      if (formValue.removeTerminalAccess !== null && formValue.removeTerminalAccess !== undefined && formValue.removeTerminalAccess != "") {
        let customfield_21700Array: any[] = [];
        let removeTerminalValue: any[] = [];
        removeTerminalValue = formValue.removeTerminalAccess;
        removeTerminalValue.forEach(element => {
          let customfield_21700 = { value: element };
          customfield_21700Array.push(customfield_21700);
        });

        fields.customfield_21700 = customfield_21700Array;
      }
      if (formValue.removeAMEXAccess !== null && formValue.removeAMEXAccess !== undefined && formValue.removeAMEXAccess != "") {
        let customfield_65602 = { value: formValue.removeAMEXAccess };
        fields.customfield_65602 = customfield_65602;
      }
    }
    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let customfield_66000: string = '';
    if (this.audit !== null && this.audit !== undefined && this.audit !== '') {
      customfield_66000 = this.audit + " On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    } else {
      customfield_66000 = "On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    }
    fields.customfield_66000 = customfield_66000;
    localStorage.setItem('audit', customfield_66000);
    console.log('Fields ', fields);
    return fields;

  }



  formPayload(formValue: any) {
    let fields: any = {};

    if (formValue.buddy !== null && formValue.buddy !== undefined && formValue.buddy != "") {
      let customfield_61100 = { name: formValue.buddy };
      fields.customfield_61100 = customfield_61100;
    }
    if (formValue.modelaccess !== null && formValue.modelaccess !== undefined && formValue.modelaccess != "") {
      let customfield_26322 = { name: formValue.modelaccess };
      fields.customfield_26322 = customfield_26322;
    }
    if (formValue.etype !== null && formValue.etype !== undefined && formValue.etype != "") {
      let customfield_31903 = { value: formValue.etype };
      fields.customfield_31903 = customfield_31903;
    }
    if (formValue.phone !== null && formValue.phone !== undefined && formValue.phone != "") {
      let customfield_26129 = { value: formValue.phone };
      fields.customfield_26129 = customfield_26129;
    }
    if (formValue.location !== null && formValue.location !== undefined && formValue.location != "") {
      let customfield_26137 = { value: formValue.location };
      fields.customfield_26137 = customfield_26137;
    }
    if (formValue.workspaceno !== null && formValue.workspaceno !== undefined && formValue.workspaceno != "") {
      let customfield_26329 = formValue.workspaceno;
      fields.customfield_26329 = customfield_26329;
    }
    if (formValue.applicationgroup !== null && formValue.applicationgroup !== undefined && formValue.applicationgroup != "") {
      let customfield_26304 = { value: formValue.applicationgroup };
      fields.customfield_26304 = customfield_26304;
    }


    if (formValue.managerphoneno !== null && formValue.managerphoneno !== undefined && formValue.managerphoneno != "") {
      let customfield_26320 = formValue.managerphoneno;
      fields.customfield_26320 = customfield_26320;
    }

    if (formValue.existingphoneno !== null && formValue.existingphoneno !== undefined && formValue.existingphoneno != "") {
      let customfield_26313 = formValue.existingphoneno;
      fields.customfield_26313 = customfield_26313;
    }

    if (formValue.htype !== null && formValue.htype !== undefined && formValue.htype != "") {
      let customfield_35300 = { value: formValue.htype };
      fields.customfield_35300 = customfield_35300;
    }

    if (formValue.difffirstname !== null && formValue.difffirstname !== undefined && formValue.difffirstname != "") {
      let customfield_26309 = formValue.difffirstname;
      fields.customfield_26309 = customfield_26309;
    }

    if (formValue.workspacecomments !== null && formValue.workspacecomments !== undefined && formValue.workspacecomments != "") {
      let customfield_26330 = formValue.workspacecomments;
      fields.customfield_26330 = customfield_26330;
    }

    if (formValue.workspaceimprovements !== null && formValue.workspaceimprovements !== undefined && formValue.workspaceimprovements != "") {
      let customfield_26331 = { value: formValue.workspaceimprovements };
      fields.customfield_26331 = customfield_26331;
    }

    if (formValue.workspaceimprovements !== null && formValue.workspaceimprovements !== undefined && formValue.workspaceimprovements != "") {
      let customfield_26331Array: any[] = [];
      let workspaceImprovementsValue: any[] = [];
      workspaceImprovementsValue = formValue.workspaceimprovements;
      workspaceImprovementsValue.forEach(element => {
        let customfield_26331 = { value: element };
        customfield_26331Array.push(customfield_26331);
      });

      fields.customfield_26331 = customfield_26331Array;
    }

    if (formValue.computingequipments !== null && formValue.computingequipments !== undefined && formValue.computingequipments != "") {
      let customfield_26308 = { value: formValue.computingequipments };
      fields.customfield_26308 = customfield_26308;
    }

    if (formValue.amexcardrequired !== null && formValue.amexcardrequired !== undefined && formValue.amexcardrequired != "") {
      let customfield_59600 = { value: formValue.amexcardrequired };
      fields.customfield_59600 = customfield_59600;
    }


    if (formValue.applicationgroup === 'Sales' || formValue.applicationgroup === 'Editorial/Sales' || formValue.applicationgroup === "Customer Operations") {

      if (formValue.salesforceaccount !== null && formValue.salesforceaccount !== undefined && formValue.salesforceaccount != "") {
        let customfield_26325 = { value: formValue.salesforceaccount };
        fields.customfield_26325 = customfield_26325;
      }
      if (formValue.salesforceuser !== null && formValue.salesforceuser !== undefined && formValue.salesforceuser != "") {
        let customfield_26326 = { value: formValue.salesforceuser };
        fields.customfield_26326 = customfield_26326;
      }
      if (formValue.salesforceuserafter !== null && formValue.salesforceuserafter !== undefined && formValue.salesforceuserafter != "") {
        let customfield_26323 = { name: formValue.salesforceuserafter };
        fields.customfield_26323 = customfield_26323;
      }
    }

    if (formValue.applicationgroup === 'Editorial' || formValue.applicationgroup === 'Editorial/Sales') {


      if (formValue.editorialperson !== null && formValue.editorialperson !== undefined && formValue.editorialperson != "") {
        let customfield_26310 = { value: formValue.editorialperson };
        fields.customfield_26310 = customfield_26310;


        if (formValue.editorialperson.toString().toLowerCase().includes('editorial') ||
          formValue.editorialperson.toString().toLowerCase().includes('news')) {

          if (formValue.bwrite !== null && formValue.bwrite !== undefined && formValue.bwrite != "") {
            let customfield_26306 = { value: formValue.bwrite };
            fields.customfield_26306 = customfield_26306;
          }
          if (formValue.bwritepublication !== null && formValue.bwritepublication !== undefined && formValue.bwritepublication != "") {
            let customfield_26305 = formValue.bwritepublication;
            fields.customfield_26305 = customfield_26305;
          }
        }
      }
      if (formValue.additionaleditorial !== null && formValue.additionaleditorial !== undefined && formValue.additionaleditorial != "") {
        let customfield_26302 = formValue.additionaleditorial;
        fields.customfield_26302 = customfield_26302;
      }

    }

    if (formValue.additionalapps !== null && formValue.additionalapps !== undefined && formValue.additionalapps != "") {
      let customfield_26301 = formValue.additionalapps;
      fields.customfield_26301 = customfield_26301;
    }
    if (formValue.additionalapplication !== null && formValue.additionalapplication !== undefined && formValue.additionalapplication != "") {
      let customfield_26300 = formValue.additionalapplication;
      fields.customfield_26300 = customfield_26300;
    }

    let loginUser = localStorage.getItem("username");
    let systemDate = new Date();
    let customfield_66000: string = '';
    if (this.audit !== undefined && this.audit !== null && this.audit !== '') {
      customfield_66000 = this.audit + " On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    } else {
      customfield_66000 = "On " + systemDate + " , " + loginUser + " has edited following details on the ticket : " + JSON.stringify(formValue);
    }
    fields.customfield_66000 = customfield_66000;
    localStorage.setItem('audit', customfield_66000);
    console.log('Fields ', fields);
    return fields;

  }


  formAuditPayload() {
    let fields: any = {};
    let loginUser = localStorage.getItem("username");
    let previousAudit = localStorage.getItem('audit');
    let systemDate = new Date();
    let assigneeAudit = " On " + systemDate + " , " + loginUser + " has assigned the ticket to IT Account Management.";
    let transitionAudit = " On " + systemDate + " , " + loginUser + " has transitioned the status of the ticket from Open to Pending Account Creation."
    let customfield_66000: String = previousAudit + transitionAudit + assigneeAudit;
    fields.customfield_66000 = customfield_66000;
    console.log('Fields ', fields);
    return fields;
  }





  /*  getUsers(input: string) {
      this.users = [];
      const params =  new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('input', input);
      const headers = {
        'Access-Control-Allow-Origin': '*'
      };
      this.http.get<any>(environment.api + "users" , { params , headers})
        .subscribe({
          next: data => {
            this.users = data?.users?.users;
            console.log('User is ', this.users);
          },
          error: error => {
            this.errorMessage = error.message;
            console.error('Error ', error);
          }
        })
  
    } 
  
  
  
    getDropdownValues() {
      this.workSpaceLocation = [];
      const params = new HttpParams()
        .set('username', this.user)
        .set('password', this.password);
      const headers = {
        'Access-Control-Allow-Origin': '*'
      };
      this.http.get<any>(environment.api + "dropdown", { params, headers })
        .subscribe({
          next: data => {
           const result: any[] = data.values;
          if (result !== null && result !== undefined && result.length > 0) {

            // HR Employee Type           
            result.forEach(element => {
              if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_31903" && element.allowedValues !== null &&
                element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                const hrEmployeeTypeList: any[] = element.allowedValues;
                hrEmployeeTypeList.forEach(item => {
                  this.hrEmployeeType.push(item.value);
                })
              }
            });


            // Hire Type         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_35300" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const hireTypeList: any[] = element.allowedValues;
                  hireTypeList.forEach(item => {
                    this.hireType.push(item.value);
                  })
                }
              });
            }

            // Phone Details        
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26129" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const phoneDetailsList: any[] = element.allowedValues;
                  phoneDetailsList.forEach(item => {
                    this.phoneDetails.push(item.value);
                  })
                }
              });
            }


            // Workspace Location      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26137" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceLocationList: any[] = element.allowedValues;
                  workSpaceLocationList.forEach(item => {
                    this.workSpaceLocation.push(item.value);
                  })
                }
              });
            }


            // Workspace Improvements   
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26331" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const workSpaceImprovementsList: any[] = element.allowedValues;
                  workSpaceImprovementsList.forEach(item => {
                    this.workSpaceImprovements.push(item.value);
                  })
                }
              });
            }

            // Computing Equipments         
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26308" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const computingEquipmentsList: any[] = element.allowedValues;
                  computingEquipmentsList.forEach(item => {
                    this.computingEquipments.push(item.value);
                  })
                }
              });
            }

            // AMEX Card Required      
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_59600" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const amexCardRequiredList: any[] = element.allowedValues;
                  amexCardRequiredList.forEach(item => {
                    this.amexCardRequired.push(item.value);
                  })
                }
              });
            }


            // Application Group   
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26304" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const applicationGroupList: any[] = element.allowedValues;
                  applicationGroupList.forEach(item => {
                    this.applicationGroup.push(item.value);
                  })
                }
              });
            }


            // New Sales Force Account
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26325" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const newSalesForceAccountList: any[] = element.allowedValues;
                  newSalesForceAccountList.forEach(item => {
                    this.newSalesForceAccount.push(item.value);
                  })
                }
              });
            }

            // salesForceUserType
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26326" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const salesForceUserTypeList: any[] = element.allowedValues;
                  salesForceUserTypeList.forEach(item => {
                    this.salesForceUserType.push(item.value);
                  })
                }
              });
            }


            // Editorial Persona
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26310" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const editorialPersonaList: any[] = element.allowedValues;
                  editorialPersonaList.forEach(item => {
                    this.editorialPersona.push(item.value);
                  })
                }
              });
            }

            // BWrite Role
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26306" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const bwriteRoleList: any[] = element.allowedValues;
                  bwriteRoleList.forEach(item => {
                    this.bwriteRole.push(item.value);
                  })
                }
              });
            }

            // Deliver Equipment To
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_26119" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const deliverEquipmentList: any[] = element.allowedValues;
                  deliverEquipmentList.forEach(item => {
                    this.deliverEquipment.push(item.value);
                  })
                }
              });
            }


            // Remove AMEX Card
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_65602" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const removeAMEXList: any[] = element.allowedValues;
                  removeAMEXList.forEach(item => {
                    this.removeAMEX.push(item.value);
                  })
                }
              });
            }

            // Remove Access (Terminal)
            if (result !== null && result !== undefined && result.length > 0) {
              result.forEach(element => {
                if (element !== null && element !== undefined && element !== "" && element.fieldId == "customfield_21700" && element.allowedValues !== null &&
                  element.allowedValues !== undefined && element.allowedValues?.length > 0) {
                  const removeAccessList: any[] = element.allowedValues;
                  removeAccessList.forEach(item => {
                    this.removeAccess.push(item.value);
                  })
                }
              });
            }




          }
  
            console.log('HR Employee Type ', this.hrEmployeeType);
            console.log('Phone Details ', this.phoneDetails);
            console.log('WorkSpace Location ', this.workSpaceLocation);
            console.log('Application Group ', this.applicationGroup);
            console.log('New Sales Force Account ', this.newSalesForceAccount);
            console.log('Sales Force User Type ', this.salesForceUserType);
            console.log('Editorial Persona ', this.editorialPersona);
            console.log('BWrite Role ', this.bwriteRole);
            console.log('Deliver Equipment To ', this.deliverEquipment);
            console.log('Remove AMEX Card ', this.removeAMEX);
            console.log('Remove Access ', this.removeAccess);
          },
          error: error => {
            this.errorMessage = error.message;
            console.error('Error ', error);
          }
        })
  
    }
  
  
  
   
  
   getTicketAudit() {       
        const params =  new HttpParams()
        .set('username', this.user)
        .set('password', this.password)
        .set('ticket', this.ticket)
        const headers = {
          'Access-Control-Allow-Origin': '*'
        };
        this.http.get<any>(environment.api + "transfer/audit" ,  {params, headers})
          .subscribe({
            next: data => {
            this.audit = data.fields?.customfield_66000;
            },
            error: error => {
              this.errorMessage = error.message;
              console.error('Error ', error);
            }
          })    
      } 
  
  
     pushPreviousManagerDetailsToJIRA(formValues: any) {
      this.previousManagerCompleted = false;
      const params =  new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('ticket', this.ticket)
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      };
      let fields = this.formPayloadForPreviousManager(formValues);   
      this.http.put<any>(environment.api + "transfer/edit" , JSON.stringify({ fields }), {params, headers })
        .subscribe({
          next: data => {
            console.log('Data is ', data);
            this.previousManagerCompleted = true;  
            if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {
              this.updateStatus();
              setTimeout (() => {           
                this.pushAuditDetailsToJIRA();         
                alert("Previous Manager Details Submited Successfully"); 
                this.router.navigate(['../../transfer'], { relativeTo: this.route });         
            }, 2000);    
            } else {
             alert("An Error occured while submitting employee details to Jira, please find the error " +  JSON.stringify(data?.errors));             
            }     
          },
          error: error => {
            this.errorMessage = error.message;
            console.error('Error ', error);
            this.previousManagerCompleted = false;
          }
        })
  
    } 
  
  
    pushDetailsToJIRA(formValues: any) { 
      this.newManagerCompleted = false;
      const params =  new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('ticket', this.ticket)
      const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-Origin': '*'
     };
      let fields = this.formPayload(formValues);  
      this.http.put<any>(environment.api + "transfer/edit" ,  JSON.stringify({ fields }) , { params , headers})    
       .subscribe({
          next: data => {
            this.newManagerCompleted = true;
            console.log('Data is ', data);
            if (data === null || data === undefined || data === '' || data?.errors == null || data?.errors == undefined ||  data?.errors == '') {
              this.updateStatus();        
              setTimeout (() => {           
                this.pushAuditDetailsToJIRA();         
                alert("New Manager Details Submited Successfully"); 
                this.router.navigate(['../../transfer'], { relativeTo: this.route });         
            }, 2000); 
            } else {
             alert("An Error occured while submitting employee details to Jira, please find the error " +  JSON.stringify(data?.errors));             
            } 
          },
          error: error => {
            this.newManagerCompleted = false;
            this.errorMessage = error.message;
            console.error('Error ', error);
          }
        }) 
    } 
  
    updateStatus() {
      const params =  new HttpParams()
      .set('username', this.user)
      .set('password', this.password)
      .set('ticket', this.ticket)
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      };
      let transition = {      
        "id":"41"      
      };
      this.http.post<any>(environment.api + "transfer/transition" , JSON.stringify({ transition }), { params, headers })
      .subscribe({
        next: data => {
          console.log('Data is ', data);  
        },
        error: error => {
          this.errorMessage = error.message;
          console.error('Error ', error);
        }
      })
    } 
  
     
  
     pushAuditDetailsToJIRA() {
        const params = new HttpParams()
        .set('username', this.user)
        .set('password', this.password)
        .set('ticket', this.ticket)
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      };
      let fields = this.formAuditPayload();
      this.http.put<any>(environment.api + "onboarding/edit", JSON.stringify({ fields }), { params, headers })
        .subscribe({
          next: data => {
            console.log('Data is ', data);
          },
          error: error => {
            this.errorMessage = error.message;
            console.error('Error ', error);
          }
        })
    
      }  */



}